import * as actionTypes from './actionTypes';
import API from '../../services/api';
import axios from 'axios';

let CancelToken = axios.CancelToken;
let fetchUsers_cancel;

export const sendTransfer = (body) => {
  fetchUsers_cancel && fetchUsers_cancel()
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(transferStart());
      API.post(`/account/admin/transfer`, body, {
        cancelToken: new CancelToken(function executor(c) {
            fetchUsers_cancel = c;
        })
      })
        .then(async res => {
          dispatch(sendTransferSuccess(res.data))
          resolve(res.data);
        })
        .catch(err => {
          dispatch(transferFail(err.response ? err.response.data.code : err))
          reject(err.response.data.code);
        })
    })
  }
}
export const transferStart = () => {
  return {
    type: actionTypes.TRANSFER_START
  }
}
export const sendTransferSuccess = (transfer) => {
  return {
    type: actionTypes.SEND_TRANSFER_SUCCESS,
    transfer,
  }
}
export const transferFail = (error) => {
  return {
    type: actionTypes.TRANSFER_FAIL,
    error
  }
}

export const transferClear = () => {
  return {
    type: actionTypes.TRANSFER_CLEAR
  }
}