import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';

const initialState = {
  statement: { count: 0, rows: [] },
  loading: false,
  loadingInfiniteScroll: false,
};

const requestStatementStart = (state, action) => {
  return updateObject(state, { statement: { count: 0, rows: [] }, loading: true });
};

const requestStatementInfiniteScrollStart = (state, action) => {
  return updateObject(state, { loadingInfiniteScroll: true });
};

const fetchStatementSuccess = (state, action) => {
  return updateObject(state, {
    statement: action.statement,
    loading: false
  });
};

const fetchStatementInfiniteScrollSuccess = (state, action) => {
  return updateObject(state, {
    statement: {...state.statement, rows: [...state.statement.rows.concat(action.statement.rows)]},
    loadingInfiniteScroll: false
  });
};

const requestStatementFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_STATEMENT_START: return requestStatementStart(state, action);
    case actionTypes.REQUEST_STATEMENT_INFINITE_SCROLL_START: return requestStatementInfiniteScrollStart(state, action);
    case actionTypes.FETCH_STATEMENT_SUCCESS: return fetchStatementSuccess(state, action);
    case actionTypes.FETCH_STATEMENT_INFINITE_SCROLL_SUCCESS: return fetchStatementInfiniteScrollSuccess(state, action);
    case actionTypes.REQUEST_STATEMENT_FAIL: return requestStatementFail(state, action);

    default: return state;
  }
};

export default reducer;
