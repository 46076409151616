
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const styles = {
  color: '#3b5998',
  icon: {
    size: 'small',
  },
  header: {
    fontSize: '0.85rem',
  },
  badge: {
    width: '160px',
  },
}

export const NavLinkStyled = styled(NavLink)`
  color: #333;
  text-decoration: none;
`