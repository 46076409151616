import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';

const INITIAL_STATE = {
	token: null,
	permissionCPF: false,
  account: {},
  sonsAccount: { count: 0, rows: [] },
  sonAccount: {},
  _id: "",
  idAccount: "",
  name: "",
  email: "",
  password: "",
	role: "",
	phone: {},
	maritalStatus: "",
  cpf: "",
  birthDate: null,
  address: {},
	status: "",
	hasDocumentError: false,
	missingSPDValidation: false,
  invite: {},
  invites: { count: 0, rows: [] },
  terms: {},
  regulamentoryDocs: { count: 0, rows: [] },
  documentPhotos: [],
  registrationId: "",
  sons: [],
  billet: {},
  error: null,
  loading: false,
  loadingInfiniteScroll: false,
}

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true })
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token, // only on login
    _id: action.user._id,
    name: action.user.name,
    email: action.user.email,
    role: action.user.role,
    cpf: action.user.cpf,
    birthDate: action.user.birthDate,
    address: action.user.address,
    hasDocumentError: action.user.hasDocumentError,
    missingSPDValidation: action.user.missingSPDValidation,
    error: null,
    loading: false
  })
}

const selectTenant = (state, action) => {
  return updateObject(state, {
    selectedAccess: action.tenant,
    loading: false
  })
}

const updateUserSelectedSiteToPrint = (state, action) => {
  return updateObject(state, {
    selectedAccess: action.selectedAccess,
    loading: false
  })
}

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path })
}

const activateSignupUserSuccess = (state, action) => {
  return updateObject(state, { loading: false });
}

const signupResendSuccess = (state, action) => {
  return updateObject(state, { loading: false });
}

const authChangeNameSuccess = (state, action) => {
  return updateObject(state, { loading: false, name: action.name });
}

const authChangePasswordSuccess = (state, action) => {
  return updateObject(state, { loading: false });
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    default:
      return state
  }
}

export default reducer