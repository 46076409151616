import React from 'react';

import {
  ListItemIcon
} from '@material-ui/core';

export default function ListItemIcons(props) {
  return (
    <ListItemIcon style={{ color: props.color, ...styles.icon }}>
      {props.icon}
    </ListItemIcon>
  )
}

const styles = {
  icon: {
    minWidth: 30,
  },
}