import { reducer as toastrReducer } from 'react-redux-toastr'
import { AUTH_LOGOUT } from "../actions/actionTypes";

import { combineReducers } from 'redux';

import authReducer from './auth';
import usersReducer from './users';
import statement from './statement';
import pix from './pix';
import account from './account';

const appReducer = combineReducers({
  auth: authReducer,
  toastr: toastrReducer,
  users: usersReducer,
  statement: statement,
  pix: pix,
  account: account,
});

const rootReducer = (state, action) => {
	if (action.type === AUTH_LOGOUT) {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
