import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../../../../store/actions";
import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import {
	Paper, Typography, FormControl, IconButton, Container, TextField, Grid
} from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import {
	FormStyled, DivFormTitle, style,
} from '../styles';

import { getStatusSon, getCardEstagio } from '../../../../../utils/status';
import { numberToCurrency } from '../../../../../utils/formatText';
import SpinnerOverlay from '../../../../../UI/SpinnerOverlay/SpinnerOverlay';

const Accordion = withStyles({
	root: {
		border: '1px solid rgba(0, 0, 0, .125)',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: 'rgba(0, 0, 0, .03)',
		borderBottom: '1px solid rgba(0, 0, 0, .125)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(2),
	},
}))(MuiAccordionDetails);

const FormFatherSons = (props) => {
	const dispatch = useDispatch();
	const { user, sons, accounts, loadingSons, invites } = useSelector(state => state.users);

	const fetchSonsAcc = useCallback(() => dispatch(actions.fetchSonsAccount(user._id)), []);
	const fetchSonsByFatherId = useCallback(() => dispatch(actions.fetchSonsByFatherId(user._id)), []);

	useEffect(() => {
		fetchSonsAcc();
		fetchSonsByFatherId();
		dispatch(actions.fetchInviteCard(user._id))
	}, []);

	const handleSon = async (e, id) => {
		e.stopPropagation();

		await dispatch(actions.fetchUsersById(id))
		props.history.push(`${id}`)
	}

	return (
		<>
			{loadingSons && <SpinnerOverlay />}
			<Container maxWidth='md' style={styles.container}>
				<Paper>
					<FormStyled onSubmit={() => { }} style={{ opacity: loadingSons ? '0.3' : '1' }}>
						<DivFormTitle>
							<Typography style={styles.title} variant='h6'>FILHOS</Typography>
						</DivFormTitle>

						{(!loadingSons && sons.count === 0) &&
							<div style={styles.notFound}>nenhum filho encontrado</div>}

						{user.sons ? user.sons.map(son => {
							let card = {};
							if (sons.count > 0) {
								card = sons.rows.find(s => s.idConta == son.idAccount);
							}
							let acc = {};
							if (accounts.count > 0) {
								acc = accounts.rows.find(s => s.id == son.idAccount);
							}
							return (
								<Accordion key={son._id}>
									<AccordionSummary
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
											<Typography style={{ fontWeight: 'bold' }}>{son.name}</Typography>
											<div style={styles.valueContainer}>
												<Typography style={styles.label}>Saldo:</Typography>
												<Typography style={styles.value}>{(son.idAccount && acc && acc.saldoDisponivelGlobal >= 0) ? `${numberToCurrency(acc.saldoDisponivelGlobal)}` : '-'}</Typography>
											</div>
											<div style={styles.valueContainer}>
												<Typography style={styles.label}>Status:</Typography>
												{son.idAccount && card ?
													<Typography style={styles.value}>{getStatusSon([card]).strStatus} </Typography> :
													son.idAccount && !card ?
													<Typography style={styles.value}>Conta sem cartão</Typography> :
													<Typography style={styles.value}>Sem conta dock</Typography>
												}
											</div>
										</div>
										<div>
											<IconButton onClick={(e) => handleSon(e, son._id)}><OpenInNew /></IconButton>
										</div>
									</AccordionSummary>

									<AccordionDetails>
										<FormControl fullWidth>
											<TextField
												disabled
												label='idAccount'
												name='idConta'
												value={son.idAccount ? son.idAccount : '-'}
												margin='normal' />
										</FormControl>
										<FormControl fullWidth>
											<TextField
												disabled
												label='CPF'
												name='cpf'
												value={son.cpf}
												margin='normal'
											/>
										</FormControl>
										<FormControl fullWidth>
											<TextField
												disabled
												label='E-mail'
												name='email'
												value={son.email}
												margin='normal'
											/>
										</FormControl>

										{(card && card.id) &&
											<>
												<FormControl fullWidth>
													<TextField
														disabled
														label='id do Cartão'
														name='cartao'
														value={card.id}
														margin='normal' />
												</FormControl>
												<FormControl fullWidth>
													<TextField
														disabled
														label='N. do cartão'
														name='cartao'
														value={card.numeroCartao}
														margin='normal' />
												</FormControl>
												<FormControl fullWidth>
													<TextField
														disabled
														label='Data de emissão'
														name='cartao'
														value={
															format(parseISO(card.dataEmissao), "dd/MM/yyyy", { locale: pt })
														}
														margin='normal' />
												</FormControl>
												<FormControl fullWidth>
													<TextField
														disabled
														label='Vencimento do cartão'
														name='cartao'
														value={
															format(parseISO(card.dataValidade), "dd/MM/yyyy", { locale: pt })
														}
														margin='normal' />
												</FormControl>
												<FormControl fullWidth>
													<TextField
														disabled
														label='Estágio do cartão'
														name='cartao'
														value={`${getCardEstagio(card).label} (${getCardEstagio(card).value}, idEstagio = ${card.idEstagioCartao})`}
														margin='normal' />
												</FormControl>
											</>
										}

									</AccordionDetails>

								</Accordion>
							)
						}
						) : null}
					</FormStyled>

					<Typography style={styles.Invitetitle} variant='h6'>
						Invites
					</Typography>
					{invites.count ? invites.rows.map(invite => {
						return (
							<Accordion key={invite._id} style={styles.InviteAcc}>
								<AccordionSummary
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
										<Typography style={{ fontWeight: 'bold' }}>{invite.name}</Typography>
										<div style={styles.valueContainer}>
											<Typography style={styles.label}>Email: </Typography>
											<Typography style={styles.value}>
												{` ${invite.email}`}
											</Typography>
										</div>
									</div>
								</AccordionSummary>
								<AccordionDetails >
									<FormControl fullWidth>
										<TextField
											disabled
											label='Pin'
											name='pin'
											value={invite ? invite.pin : '-'}
											margin='normal' />
									</FormControl>
									<FormControl fullWidth>
										<TextField
											disabled
											label='Data do invite'
											name='inviteDate'
											value={invite ?
												format(parseISO(invite.createdAt), "dd/MM/yyyy", { locale: pt }) : '-'}
											margin='normal' />
									</FormControl>
								</AccordionDetails>
							</Accordion>
						)
					}) : <div style={styles.notFound}>nenhum invite encontrado</div>}
					<div style={{ height: '1.5rem' }}></div>
				</Paper>
			</Container>
		</>
	)
}

export default FormFatherSons;

const styles = {
	container: {
		position: 'relative',
	},
	title: {
		fontSize: style.fontSize,
	},
	Invitetitle: {
		fontSize: '1.4rem',
		marginLeft: '1.5rem'
	},
	valueContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '0.25rem'
	},
	label: {
		fontSize: '0.9rem',
		color: 'grey',
	},
	value: {
		fontSize: '0.9rem',
	},
	notFound: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '1rem 0'
	},
	InviteAcc: {
		marginLeft: 20,
		marginRight: 20,
		background: 'rgba(247,247,247, 0.2)'
	}
}


