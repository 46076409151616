import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../../../../store/actions";
import moment from 'moment';

import {
	Grid, Paper, Typography, FormControl, Container, TextField, IconButton,
} from '@material-ui/core';
import { FaWhatsapp } from 'react-icons/fa'
import { Alert } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Chip from '@material-ui/core/Chip';
import {
	FormStyled, DivFormTitle, style,
} from '../styles';
import { currencyToNumber, capitalize, onlyNumbers } from '../../../../../utils/formatText'

import FormDialog from '../components/FormDialog';
import SpinnerOverlay from '../../../../../UI/SpinnerOverlay/SpinnerOverlay';
import { emailValidator } from '../../../../../utils/emailValidator';
import { phoneMask } from '../../../../../utils/phoneValidator';
import { format } from 'date-fns'

const FormFather = (props, userId) => {

	const dispatch = useDispatch();
	const { user, loadingUser, loadingUpdateUser } = useSelector(state => state.users);
	const [edit, setEdit] = useState(false);
	const [success, setSuccess] = useState(false);
	const [errorEdit, setErrorEdit] = useState(false);
	const [email, setEmail] = useState(user.email);
	const [cep, setCep] = useState(user.address.zipCode);
	const [street, setStreet] = useState(user.address.street);
	const [neighborhood, setNeighborhood] = useState(user.address.neighborhood);
	const [number, setNumber] = useState(user.address.number);
	const [complement, setComplement] = useState(user.address.complement);
	const [phone, setPhone] = useState(user.phone.number ? `(${user.phone.areaCode}) ${user.phone.number}` : '-');
	const { status, error, statusAcc, errorC, errorD, statusD } = useSelector(state => state.account);


	const toggleEdit = () => setEdit(o => !o);

	const saveEdit = () => {
		setEdit(o => !o)
		dispatch(actions.updateUser(user._id, email, onlyNumbers(phone), cep, neighborhood, street, number, complement, user.birthDate)).then(res => {
			dispatch(actions.fetchUsersById(user._id)).then(res => {
				setSuccess(true)
				setErrorEdit(false)
			})
		}).catch(err => {
			dispatch(actions.fetchUsersById(user._id)).then(res => {
				setErrorEdit(true)
				setSuccess(false)
			})
		})
	};


	useEffect(() => {
		dispatch(actions.transferClear())
	}, [user, dispatch])

	return (
		<>
			<Container maxWidth='md' style={styles.container}>
				{loadingUser && loadingUpdateUser && <SpinnerOverlay />}
				<Paper>
					<FormStyled onSubmit={() => { }} style={{ opacity: loadingUser ? '0.3' : '1' }}>
						<DivFormTitle>
							<Typography style={{ ...styles.title, minHeight: 50, paddingTop: 12 }} variant='h6'>DETALHES DO PAI</Typography>

							{!edit &&
								<IconButton
									color="#8e00a7"
									aria-label="Edit"
									onClick={() => toggleEdit()}
									edge="edit"
								>
									<EditIcon />
								</IconButton>
							}
							{edit &&
								<>
									<IconButton
										aria-label="Cancel"
										onClick={() => toggleEdit()}
										edge="cancel"
										style={{ position: 'absolute', right: '12%', top: '2%', color: 'red' }}
									>
										<CancelIcon />
									</IconButton>
									<IconButton
										aria-label="Save"
										onClick={() => saveEdit()}
										edge="save"
										style={{ position: 'absolute', right: '4%', top: '2%', color: 'green' }}
									>
										<SaveIcon />
									</IconButton>
								</>
							}
						</DivFormTitle>

						{(status || statusAcc || statusD || success) &&
							<Alert style={{ marginTop: '10px' }}
								severity="success"
								onClose={() => dispatch(actions.transferClear())}>
								Operação realizada com sucesso!
							</Alert>
						}

						{(error || errorC || errorD || errorEdit) &&
							<Alert style={{ marginTop: '10px' }} severity="error"
								onClose={() => dispatch(actions.transferClear())}>
								Não foi possível realizar a operação
							</Alert>
						}

						{user.missingSPDValidation &&
							<Alert style={{ marginTop: '10px' }} severity="error">
								Usuário no SPD, será necessário reenviar os documentos.
							</Alert>
						}
						<>
							<FormControl fullWidth>
								<TextField
									disabled
									label='Documentos pendentes'
									name='hasDocumentError'
									margin='normal' />
								<Chip label={user.hasDocumentError ? 'Documentação pendente' : 'Nenhum'}
									color={user.hasDocumentError ? 'secondary' : 'primary'}
									style={styles.widthMax} />
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled
									label='Em análise de fraude (SPD)'
									name='missingSPDValidation'
									margin='normal' />
								<Chip label={user.missingSPDValidation ? 'SPD em análise' : 'Não'}
									color={user.missingSPDValidation ? 'secondary' : 'primary'}
									style={styles.widthMax} />
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled
									label='idAccount'
									name='account'
									// onChange={event => setName(event.target.value)}
									value={user.idAccount ? user.idAccount : '-'}
									margin='normal' />
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled
									label='idIndividual'
									name='individual'
									// onChange={event => setName(event.target.value)}
									value={user.idIndividual ? user.idIndividual : '-'}
									margin='normal' />
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled
									label='Saldo'
									name='saldo'
									// onChange={event => setName(event.target.value)}
									value={user.saldoDisponivel ? user.saldoDisponivel : '-'}
									margin='normal' />
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled
									label='Nome'
									name='nome'
									// onChange={event => setName(event.target.value)}
									value={capitalize(user.name)}
									margin='normal' />
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled
									label='CPF'
									name='cpf'
									// onChange={event => setCpf(event.target.value)}
									value={user.cpf}
									margin='normal'
								/>
							<FormControl fullWidth>
								<KeyboardDatePicker
									disabled={!edit}
									label='Data de Nascimento'
									name='birth'
									value={moment(user.birthDate).add(3, 'hours').format('MM/DD/YYYY')}
									maxDate={new Date()}
									format="dd/MM/yyyy"
								/>
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled
									label='Nome da mãe'
									name='mothername'
									// onChange={event => setName(event.target.value)}
									value={user.motherName ? user.motherName : ''}
									margin='normal' />
							</FormControl>
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled={!edit}
									label='E-mail'
									name='email'
									onChange={event => setEmail(event.target.value)}
									value={email}
									margin='normal' />
							</FormControl>
							{!emailValidator(email) &&
								<div style={{ color: 'red' }}>Email inválido!</div>
							}
							<FormControl fullWidth>
								<Grid container direction="row" alignItems="center" justify="space-between">
									<TextField
										disabled={!edit}
										label='Celular'
										name='telefones'
										onChange={event => setPhone(event.target.value)}
										value={phoneMask(phone)}
										type='phone'
										margin='normal'
										style={{ width: '100%' }}
									/>
									<a
										href={`https://wa.me/${user.phone.number ?
											`+55${user.phone.areaCode}${user.phone.number}`
											: '-'}?text=Ol%C3%A1%2C%20${user.name}%20aqui%20quem%20fala%20%C3%A9%20a%20equipe%20da%20YoursBank`}
										style={{ position: 'absolute', marginLeft: '95%' }}
										target="_blank"
										rel="noopener noreferrer"
									>
										<FaWhatsapp fontSize={35} />
									</a>
								</Grid>
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled
									label='Cidade'
									name='city'
									multiline
									// onChange={event => setPhone(phoneMask(event.target.value))}
									value={user.address.street ? `${user.address.city}/${user.address.uf}` : '-'}
									margin='normal' />
							</FormControl>

							{!edit &&
								<FormControl fullWidth>
									<TextField
										disabled
										label='Endereço'
										name='address'
										multiline
										// onChange={event => setPhone(phoneMask(event.target.value))}
										value={user.address.street ? `${user.address.street}, ${user.address.number}, ${user.address.complement}, ${user.address.neighborhood}, ${user.address.zipCode}` : '-'}
										margin='normal' />
								</FormControl>
							}

							{edit &&
								<>
									<FormControl fullWidth>
										<TextField
											label='CEP'
											name='cep'
											multiline
											onChange={event => setCep(event.target.value)}
											value={cep}
											margin='normal' />
									</FormControl>
									<FormControl fullWidth>
										<TextField
											label='Logradouro'
											name='street'
											multiline
											onChange={event => setStreet(event.target.value.slice(0, 40))}
											value={street}
											margin='normal' />
									</FormControl>
									<FormControl fullWidth>
										<TextField
											label='Bairro'
											name='neighborhood'
											multiline
											onChange={event => setNeighborhood(event.target.value.slice(0, 40))}
											value={neighborhood}
											margin='normal' />
									</FormControl>
									<FormControl fullWidth>
										<TextField
											label='Número'
											name='number'
											multiline
											onChange={event => setNumber(event.target.value)}
											value={number}
											margin='normal'
											type="number" />
									</FormControl>
									<FormControl fullWidth>
										<TextField
											label='Complemento'
											name='complement'
											multiline
											onChange={event => setComplement(event.target.value.slice(0, 30))}
											value={complement}
											margin='normal' />
									</FormControl>
								</>
							}

							<FormControl fullWidth>
								<TextField
									disabled
									label='Estado Civil'
									name='civil'
									// onChange={event => setPhone(phoneMask(event.target.value))}
									value={user.maritalStatus}
									margin='normal' />
							</FormControl>
						</>

					</FormStyled>
				</Paper>
			</Container>
		</>
	)
}

export default FormFather;

const styles = {
	container: {
		position: 'relative'
	},
	title: {
		fontSize: style.fontSize,
	},
	widthMax: {
		width: 'max-content'
	}
}


