import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Typography,
  Paper,
} from '@material-ui/core';

import * as actions from '../../../../store/actions';

import { FormStyled, DivFormTitle, style } from './styles';
import FormDialog from './components/FormDialog';
import { currencyToNumber, capitalize } from '../../../../utils/formatText'

const FormConfig = () => {

  const dispatch = useDispatch();
  const { openDialog, openDialogCancel, openDialogDelete } = useSelector(state => state.account);
  const { user, loadingUser, openDialogChangeSpdStatus } = useSelector(state => state.users);



  const handleSendTransfer = (valueText) => {
    const body = {
      destinationAccount: user.idAccount,
      originAccount: '6',
      amount: currencyToNumber(valueText),
      description: "Transferência Yours"
    }
    dispatch(actions.sendTransfer(body))
  }

  const handleCancelAccount = () => {
    dispatch(actions.fetchCancelAccount(user._id, user.idAccount))
  }

  const handleDeleteAccount = () => {
    dispatch(actions.fetchDeleteAccount(user._id))
  }

  const handleChangeSpdStatus = () => {
    dispatch(actions.changeSpdStatus(user._id))
  }

  return (
    <Container maxWidth='md' style={{ position: 'relative' }}>
      <Paper>
        <FormStyled>
          <DivFormTitle>
            <Typography style={{ fontSize: style.fontSize }} variant='h6'>Configurações</Typography>
          </DivFormTitle>
          <FormDialog
            sendTransfer={handleSendTransfer}
            dialogstatus={openDialog}
            cancelAcc={handleCancelAccount}
            dialogstatusCancel={openDialogCancel}
            deleteAcc={handleDeleteAccount}
            dialogstatusDelete={openDialogDelete}
            removeSpdStatus={handleChangeSpdStatus}
            dialogSpdStatus={openDialogChangeSpdStatus}
          />
        </FormStyled>
      </Paper>
    </Container>
  );
};

export default FormConfig;
