import * as actionTypes from './actionTypes';
import API from '../../services/api';
import axios from 'axios';

let CancelToken = axios.CancelToken;
let fetchPix_cancel;

export const requestPixStart = () => {
	return {
		type: actionTypes.REQUEST_PIX_START
	};
};

export const requestPixInfiniteScrollStart = () => {
	return {
		type: actionTypes.REQUEST_PIX_INFINITE_SCROLL_START
	};
};

export const requestPixLimitStart = () => {
	return {
		type: actionTypes.REQUEST_PIX_LIMIT_START
	};
};

export const requestPixKeysStart = () => {
	return {
		type: actionTypes.REQUEST_PIX_KEYS_START
	};
};

export const requestPixFail = (error) => {
	return {
		type: actionTypes.REQUEST_PIX_FAIL,
		error: error
	};
};

export const addPixLimitFail = (error) => {
	return {
		type: actionTypes.ADD_PIX_LIMIT_FAIL,
		error: error,
	};
};

export const requestPixKeysFail = (error) => {
	return {
		type: actionTypes.REQUEST_PIX_KEYS_FAIL,
		error: error,
	};
};

export const fetchPixSuccess = (pix) => {
	return {
		type: actionTypes.FETCH_PIX_SUCCESS,
		pix,
	};
};

export const addPixLimitSuccess = (pixCount, pixTotalLimit) => {
	return {
		type: actionTypes.ADD_PIX_LIMIT_SUCCESS,
		pixCount,
    pixTotalLimit,
	};
};

export const fetchPixKeysSuccess = (keys) => {
	return {
		type: actionTypes.FETCH_PIX_KEYS_SUCCESS,
		keys,
	};
};

export const fetchPixInfiniteScrollSuccess = (pix) => {
	return {
		type: actionTypes.FETCH_PIX_INFINITE_SCROLL_SUCCESS,
		pix,
	};
};

export const fetchPix = (id, page, limit, infiniteScroll = false) => {
  fetchPix_cancel && fetchPix_cancel();
  return dispatch => {
		return new Promise((resolve, reject) => {
			if (!infiniteScroll) {
				dispatch(requestPixStart());
			} else {
				dispatch(requestPixInfiniteScrollStart());
			}
			const queryParams = `?page=${!page || page < 1 ? 1 : page}&limit=${!limit || limit < 5 ? 10 : limit}`;
			API.get(`/account/admin/pix/receipts/${id}` + queryParams, {
				cancelToken: new CancelToken(function executor(c) {
					fetchPix_cancel = c;
				})
			})
				.then(res => {
					if (!infiniteScroll) {
						dispatch(fetchPixSuccess(res.data));
					} else {
						dispatch(fetchPixInfiniteScrollSuccess(res.data));
					}
					resolve(res.data);
				})
				.catch(err => {
					dispatch(requestPixFail(err));
					reject(err);
				})
		})
	};
}

export const fetchPixLimit = (id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(requestPixLimitStart());
      API.get(`/account/admin/pix/count-pix-transfers/${id}`)
        .then(res => {
          dispatch(addPixLimitSuccess(res.data.pixCount, res.data.pixTotalLimit));
          resolve(res.data);
        })
        .catch(err => {
          dispatch(addPixLimitFail(err));
					reject(err);
        })
    });
  }
}

export const fetchPixKeys = (id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(requestPixKeysStart());
      API.get(`/account/admin/pix/listpix/${id}`)
        .then(res => {
          console.log(res.data.keys);
          dispatch(fetchPixKeysSuccess(res.data.keys));
          resolve(res.data);
        })
        .catch(err => {
          dispatch(requestPixKeysFail(err));
					reject(err);
        })
    });
  }
}

export const addPixLimit = (id, limit) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(requestPixLimitStart());
      API.post(`/account/admin/pix/addtransfer/${id}`, {
        additionalPix: limit,
      })
        .then(res => {
          dispatch(addPixLimitSuccess(res.data.pixCount, res.data.pixTotalLimit));
          resolve(res.data.pix);
        })
        .catch(err => {
          dispatch(addPixLimitFail(err));
					reject(err);
        })
    });
  }
}
