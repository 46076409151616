import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  List, ListSubheader,
} from '@material-ui/core';

import { Person, Notifications } from '@material-ui/icons';

import ListItemStyled from './ListItem';

import { styles } from './styles';

const objListItems = [
  { type: 'area1', label: 'Usuários', urlParam: '', roles: ['admin'], icon: <Person /> },
  { type: 'area1', label: 'Push Notifications', urlParam: 'push', roles: ['admin'], icon: <Notifications /> },
]

const ListItems = () => {
  const [selected, setSelected] = useState({});

	return (
    <>
      <List subheader={<ListSubheader component="div" id="nested-list-subheader1" style={{ backgroundColor: 'white' }}>{<span style={styles.header}>Área Admin</span>}</ListSubheader>}>
				{objListItems.filter(key => key.type === 'area1').map(key => (
					<ListItemStyled
						key={key.label}
						label={key.label}
						url={key.urlParam}
						icon={key.icon}
						selected={selected === key.urlParam}
						handleClickItem={() => setSelected(key.urlParam)}
					/>))}
      </List>
    </>
  );
}

export default ListItems;
