import React from "react";
import { useDispatch, } from "react-redux";
import * as actions from "../../../store/actions";

import { TextField, Button, Paper, Grid } from '@material-ui/core';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Alert, AlertTitle } from '@material-ui/lab';

import Colors from "../../../constants/Colors";

const Push = (props) => {

  const [target, setTarget] = React.useState('');
  const [targetError, setTargetError] = React.useState('');

  const [title, setTitle] = React.useState('');
  const [titleError, setTitleError] = React.useState('');
  const [titleSize, setTitleSize] = React.useState(50);

  const [msg, setMsg] = React.useState('');
  const [msgError, setMsgError] = React.useState('');
  const [msgSize, setMsgSize] = React.useState(150);

  const [alertType, setAlertType] = React.useState();
  const [alertMsg, setAlertMsg] = React.useState('');

  const [btnDisable, setBtnDisable] = React.useState(false);

  const handleChange = (event) => {
    setTarget(event.target.value);
    setTargetError(false);
  };

  const dispatch = useDispatch();

  const _handlerTextField = e => {
    if (e.target.id === 'title') {
      if (50 - e.target.value.length >= 0) {
        setTitleSize(50 - e.target.value.length)
        setTitleError(false)
        setTitle(e.target.value)
      }
    } else {
      if (150 - e.target.value.length >= 0) {
        setMsgSize(150 - e.target.value.length)
        setMsgError(false)
        setMsg(e.target.value)
      }
    }
  }

  const sendPushNotification = () => {
    if (!title) { setTitleError(true); return }
    if (!msg) { setMsgError(true); return }
    if (!target) { setTargetError(true); return }

    onAlertClose();
    setBtnDisable(true)

    dispatch(actions.fetchPush(title, msg, target))
      .then(res => {
        setAlertMsg(<>
          <AlertTitle>Push notification disparado com sucesso!</AlertTitle>
          Usuários: {res.count} | Enviado: {res.sent}
        </>
        );
        setAlertType('success');
        setTitle('');
        setTitleSize(50);
        setMsg('');
        setMsgSize(150);
        setTarget('')
        setBtnDisable(false)
      })
      .catch(err => {
        setAlertMsg('Não foi possível disparar a notificação!')
        setAlertType('error')
        setBtnDisable(false)
      });
  };

  const onAlertClose = () => {
    setAlertMsg('');
    setAlertType('');
  }

  return (
    <Grid container justify="center" >
      <Grid item md={6} xl={4} xs={12}>
        <Paper style={styles.Paper}>
          <form autoComplete="off">
            <TextField
              error={titleError}
              style={styles.formControl}
              id="title"
              label={`Título${titleSize === 50 ? `` : ` - (${titleSize})`}`}
              value={title}
              placeholder="Título da notificação"
              variant="outlined"
              onChange={_handlerTextField}
            />
            <TextField
              rows={4}
              multiline
              error={msgError}
              style={styles.formControl}
              id="msg"
              label={`Mensagem${msgSize === 150 ? `` : ` - (${msgSize})`}`}
              value={msg}
              placeholder="Mensagem da notificação"
              variant="outlined"
              onChange={_handlerTextField}
            />

            <FormControl variant="outlined" style={styles.formControl} error={targetError}>
              <InputLabel id="demo-simple-select-outlined-label">Público alvo</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={target}
                onChange={handleChange}
                label="Público alvo"
              >
                <MenuItem value="">
                  <em>Selecione...</em>
                </MenuItem>
                <MenuItem value={'FWD'}>Pais sem conta na DOCK</MenuItem>
                <MenuItem value={'FWS'}>Pais com conta na DOCK, mas sem filhos cadastrados</MenuItem>
                <MenuItem value={'SWF'}>Filhos sem pai vinculado</MenuItem>
                <MenuItem value={'SFWC'}>Filhos com pai, mas sem cartão</MenuItem>
                <MenuItem value={'SFC'}>Filhos com pai e cartão</MenuItem>
                <MenuItem value={'SCI'}>Filhos com cartão BLOQUEADO/CANCELADO</MenuItem>
                <MenuItem value={'FSC'}>Pais com filhos que possuem cartão</MenuItem>
                <MenuItem value={'FATHER'}>Todos os pais</MenuItem>
                <MenuItem value={'SON'}>Todos os filhos</MenuItem>
                <MenuItem value={'ALL'}>Todos os usuários</MenuItem>
              </Select>
            </FormControl>

          </form>

          <Button variant="contained" style={styles.button} onClick={sendPushNotification} disabled={btnDisable}>
            {!btnDisable ? 'DISPARAR NOTIFICAÇÃO' : 'DISPARANDO...'}
          </Button>

        </Paper>

        {alertType && <Alert style={{ marginTop: '10px' }} severity={alertType} onClose={onAlertClose}>{alertMsg}</Alert>}

      </Grid>
    </Grid>
  );

}

export default Push;

const styles = {
  formControl: {
    minWidth: '100%',
    marginBottom: '1rem'
  },
  input: {
    minWidth: '100%',
    marginBottom: '1rem'
  },
  Paper: {
    padding: '1rem'
  },
  button: {
    minWidth: '100%',
    padding: '.5rem',
    backgroundColor: Colors.secondary,
    color: '#fff'
  }
}
