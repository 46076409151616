
export const numberToCurrency = (value) => {
  if (typeof value === 'number') {
    return (value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    })
  } else {
    return '...'
  }
}
export const currencyToNumber = (text) => {
  return Number(text.toString().replace('R$', '').replace(',', '.'))
}

export const onlyNumbers = value => {
  return value.replace(/\D/g, '');
}

export const capitalize = (str) => {
  return str.toLowerCase()
  .split(' ')
  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');
  }