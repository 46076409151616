import React from 'react'
import { bool, string, number } from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const defaultMaskOptions = {
  prefix: 'R$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })

  return <MaskedInput 
  style={{
  fontSize: '18px',
  padding: '8px 15px',
  border: '1px solid #666666',
  borderRadius: '4px',
  color: '#333',
  }}
  mask={currencyMask} {...inputProps} />
}

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
}

CurrencyInput.propTypes = {
  inputmode: string,
    prefix: string,
    suffix: string,
    includeThousandsSeparator: bool,
    thousandsSeparatorSymbol: string,
    allowDecimal: bool,
    decimalSymbol: string,
    decimalLimit: string,
    requireDecimal: bool,
    allowNegative: bool,
    allowLeadingZeroes: bool,
    integerLimit: number,
}

export default CurrencyInput