import React from 'react';

import {
  ListItem, Badge,
} from '@material-ui/core';

import ListItemIcon from './ListItemIcon';
import ListItemText from './ListItemText';

import { styles, NavLinkStyled } from './styles';

const ListItemStyled = (props) => {
	const {
		url,
		selected,
		icon,
		label,
		handleClickItem,
		badge,
		dense,
	} = props;

  return (
		<NavLinkStyled to={"/" + url}>
			<ListItem
				style={{ backgroundColor: selected && 'rgba(0,0,0,.05)' }}
				button
				onClick={() => handleClickItem(url)}
				dense={dense}
			>
				<ListItemIcon icon={icon} color={selected && styles.color} />
				
				{badge ?
					<Badge style={styles.badge} color="secondary" badgeContent={badge}>
						<ListItemText label={label} color={selected && styles.color} />
					</Badge>
					: <ListItemText label={label} color={selected && styles.color} />}
				
			</ListItem>
		</NavLinkStyled>
  );
}

export default ListItemStyled;
