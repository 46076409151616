import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../store/actions';
import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import { Alert } from '@material-ui/lab';
import {
  Paper,
  Typography,
  Container,
  FormControl,
  TextField,
  Grid,
  Button,
} from '@material-ui/core';

import {
  FormStyled,
  DivFormTitle,
  style,
} from '../styles';

import { getStatusSon, getCardStatus, getCardStatusColor } from '../../../../../utils/status';
import SpinnerOverlay from '../../../../../UI/SpinnerOverlay/SpinnerOverlay';
import ConfirmDialog from '../../../../../UI/Confirm/ConfirmDialog';
import TrackCard from './TrackCard';
import Card from './components/Card';

const FormCard = () => {
  const dispatch = useDispatch();
  const { user, sonCards, loadingCards, track, sonVirtualCards, loadingVirtualCards } = useSelector(state => state.users);
  const [currentCard, setCurrentCard] = useState({ index: -1, id: '', number: '' });
  const [alertSuccess, setAlertSuccess] = useState(false)
  const [alerts, setAlerts] = useState(false)
  const [alertSuccessVirtualCard, setAlertSuccessVirtualCard] = useState(false)
  const [alertsVirtualCard, setAlertsVirtualCard] = useState(false)
  const [open, setOpen] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openBlockVirtualCard, setOpenBlockVirtualCard] = useState(false);
  const [openCancelVirtualCard, setOpenCancelVirtualCard] = useState(false);

  const toggleOpen = () => setOpen(o => !o);

  const toggleOpenBlock = () => setOpenBlock(o => !o);

  const toggleOpenCancel = () => setOpenCancel(o => !o);

  const toggleOpenBlockVirtualCard = () => setOpenBlockVirtualCard(o => !o);

  const toggleOpenCancelVirtualCard = () => setOpenCancelVirtualCard(o => !o);

  const fetchSonCards = useCallback(() => dispatch(actions.fetchSonCardsById(user._id)).then(res => fetchSonVirtualCards()), [dispatch, user._id]);
  const fetchSonVirtualCards = useCallback(() => dispatch(actions.fetchSonVirtualCardsById(user._id)).then(res => console.log('asdfasdfasdfa sdfa f ', sonVirtualCards != null)), [dispatch, user._id]);

  useEffect(() => {
    fetchSonCards();
  }, [fetchSonCards]);

  const handleRequestDuplicate = (charging, idCard) => {
    setOpen(false);
    setCurrentCard({ index: -1, id: '', number: '' });
    dispatch(actions.fetchRequestCardDuplicate(user._id, charging, idCard))
      .then(res => setAlertSuccess(true))
      .catch(err => setAlerts(true))
      .finally(() => fetchSonCards());
  }

  const handleRequestBlockCard = (cardId) => {
    setOpenBlock(false);
    setCurrentCard({ index: -1, id: '', number: '' });
    dispatch(actions.fetchRequestBlockCard(user._id, cardId))
      .then(res => setAlertSuccess(true))
      .catch(err => setAlerts(true))
      .finally(() => fetchSonCards());
  }

  const handleRequestUnblockCard = (cardId, unblockCode) => {
    setOpenBlock(false);
    setCurrentCard({ index: -1, id: '', number: '' });
    dispatch(actions.fetchRequestUnblockCard(user._id, cardId, unblockCode))
      .then(res => setAlertSuccess(true))
      .catch(err => setAlerts(true))
      .finally(() => fetchSonCards());
  }

  const handleRequestCancelCard = (cardId) => {
    setOpenCancel(false);
    setCurrentCard({ index: -1, id: '', number: '' });
    dispatch(actions.fetchRequestCancelCard(user._id, cardId))
      .then(res => setAlertSuccess(true))
      .catch(err => setAlerts(true))
      .finally(() => fetchSonCards());
  }

  const handleRequestUnblockVirtualCard = (cardId, unblockCode) => {
    setOpenBlockVirtualCard(false);
    dispatch(actions.fetchRequestUnblockCard(user._id, cardId, unblockCode))
      .then(res => setAlertSuccess(true))
      .catch(err => setAlerts(true))
      .finally(() => fetchSonVirtualCards());
  }

  const handleRequestBlockVirtualCard = (cardId) => {
    setOpenCancelVirtualCard(false);
    dispatch(actions.fetchRequestCancelCard(user._id, cardId))
      .then(res => setAlertSuccessVirtualCard(true))
      .catch(err => setAlertsVirtualCard(true))
      .finally(() => fetchSonVirtualCards());
  }

  const handleRequestCancelVirtualCard = (cardId) => {
    setOpenCancelVirtualCard(false);
    dispatch(actions.fetchRequestCancelCard(user._id, cardId))
      .then(res => setAlertSuccessVirtualCard(true))
      .catch(err => setAlertsVirtualCard(true))
      .finally(() => fetchSonVirtualCards());
  }

  const updateCurrentCard = (index, id, number) => {
    if (currentCard.id === id) return setCurrentCard({ index: -1, id: '', number: '' });
    return setCurrentCard({ index, id, number })
  }

  return (
    <Container maxWidth='md' style={styles.container}>
      {loadingCards || loadingVirtualCards && <SpinnerOverlay />}
      <Paper>
        <FormStyled onSubmit={() => { }} style={{ opacity: loadingCards ? '0.3' : '1' }}>
          <DivFormTitle>
            <Typography style={styles.title} variant='h6'>CARTÕES FÍSICOS</Typography>
          </DivFormTitle>
          {sonCards.cards.length > 0 ? (
            <>
              <Grid container spacing={2}>
                {sonCards.cards.map(((c, i) =>
                  <Grid item key={c.id} xs={4}>
                    <Card
                      id={c.id}
                      status={getCardStatus(c.idStatus).label}
                      statusColor={getCardStatusColor(getCardStatus(c.idStatus).value)}
                      number={c.numeroCartao}
                      issueDate={c.dataEmissao}
                      validThru={c.dataValidade}
                      toggleActive={() => updateCurrentCard(i, c.id, c.numeroCartao)}
                      active={currentCard.id === c.id}
                    />
                  </Grid>
                ))}
              </Grid>
              {alertSuccess && (
                <Alert style={{ marginTop: '10px' }} severity="success" onClose={() => setAlertSuccess(false)}>
                  Operação concluída com sucesso!
                </Alert>
              )}
              {alerts && (
                <Alert style={{ marginTop: '10px' }} severity="error" onClose={() => setAlerts(false)}>
                  Não foi possível realizar a operação.
                </Alert>
              )}
              {currentCard.index !== -1 && (
                <>
                  <DivFormTitle>
                    <Typography
                      style={{ ...styles.title, marginTop: 16 }}
                      variant="h6"
                    >
                      DETALHES DO CARTÃO
                    </Typography>
                  </DivFormTitle>
                  <FormControl fullWidth>
                    <TextField
                      disabled
                      label="N. do cartão"
                      name="cartao"
                      value={sonCards.cards[currentCard.index].numeroCartao || '-'}
                      margin="normal"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      disabled
                      label="ID"
                      name="cardId"
                      value={sonCards.cards[currentCard.index].id || '-'}
                      margin="normal"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      disabled
                      label="Status"
                      name="status"
                      value={getStatusSon([sonCards.cards[currentCard.index]]).strStatus || '-'}
                      margin="normal"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      disabled
                      label="Emissão"
                      name="emissão"
                      value={format(parseISO(sonCards.cards[currentCard.index].dataEmissao), 'dd/MM/yyyy', { locale: pt }) || '-'}
                      margin="normal"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      disabled
                      label="Validade"
                      name="vencimento"
                      value={format(parseISO(sonCards.cards[currentCard.index].dataValidade), 'dd/MM/yyyy', { locale: pt }) || '-'}
                      margin="normal"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      disabled
                      label="Track do cartão"
                      name="track"
                      value={track.message ? track.message : '-'}
                      margin="normal"
                    />
                  </FormControl>
                  <Button
                    variant="outlined"
                    style={{ marginTop: '10px' }}
                    color="primary"
                    onClick={toggleOpen}
                  >
                    Solicitar segunda via
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ marginTop: '10px', marginLeft: '10px' }}
                    color="primary"
                    onClick={toggleOpenBlock}
                  >
                    {getCardStatus(sonCards.cards[currentCard.index].idStatus).value === 'blocked'
                      ? 'Desbloquear'
                      : 'Bloquear'} cartão
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ marginTop: '10px', marginLeft: '10px' }}
                    color="primary"
                    onClick={toggleOpenCancel}
                  >
                    Cancelar cartão
                  </Button>
                  <TrackCard cardId={currentCard.id} />
                </>
              )}
            </>
          ) : (
            <DivFormTitle>
              <Typography
                style={{ ...styles.title, marginTop: 16 }}
                variant="h6"
              >
                Nenhum cartão físico encontrado para o usuário!
              </Typography>
            </DivFormTitle>
          )}
        </FormStyled>
      </Paper>
      {/* Segunda via */}
      <ConfirmDialog
        show={open}
        proceed={() => handleRequestDuplicate(true, currentCard.id)}
        proceed1={() => handleRequestDuplicate(false, currentCard.id)}
        dismiss={() => toggleOpen()}
        cancel={() => toggleOpen()}
        confirmation={`Solicitando a segunda via do cartão ${currentCard.number}! \n Deseja cobrar a taxa de R$ 18.90 do usuário?`}
        proceedText="Cobrar"
        proceedText1="Não Cobrar"
        cancelText="Voltar"
        options={{
          title: 'Solicitar segunda via do cartão?',
          titleColor: '#777'
        }}
      />

      {/* Desbloquear/Bloquear cartão */}
      {currentCard.index !== -1
        && getCardStatus(sonCards.cards[currentCard.index].idStatus).value === 'blocked'
        ? (
          <ConfirmDialog
            show={openBlock}
            proceed={() => handleRequestUnblockCard(currentCard.id, sonCards.cards[currentCard.index].codigoDesbloqueio)}
            dismiss={() => toggleOpenBlock()}
            cancel={() => toggleOpenBlock()}
            confirmation={`Deseja realizar o desbloqueio do cartão ${currentCard.number}?\nEsse processo não pode ser revertido!`}
            proceedText="Solicitar desbloqueio"
            cancelText="Voltar"
            options={{
              title: 'Solicitar o desbloqueio cartao?',
              titleColor: '#777'
            }}
          />
        ) : (
          <ConfirmDialog
            show={openBlock}
            proceed={() => handleRequestBlockCard(currentCard.id)}
            dismiss={() => toggleOpenBlock()}
            cancel={() => toggleOpenBlock()}
            confirmation={`Deseja realizar o bloqueio do cartão ${currentCard.number}?\nEsse processo não pode ser revertido!`}
            proceedText="Solicitar bloqueio"
            cancelText="Voltar"
            options={{
              title: 'Solicitar o bloqueio cartão?',
              titleColor: '#777'
            }}
          />
        )}

      {/* Cancelar cartão */}
      <ConfirmDialog
        show={openCancel}
        proceed={() => handleRequestCancelCard(currentCard.id)}
        dismiss={() => toggleOpenCancel()}
        cancel={() => toggleOpenCancel()}
        confirmation={`Deseja realizar o cancelamento do cartão ${currentCard.number}?\nEsse processo não pode ser revertido!`}
        proceedText="Solicitar cancelamento"
        cancelText="Voltar"
        options={{
          title: 'Solicitar o cancelamento do cartão?',
          titleColor: '#777'
        }}
      />

      <Paper style={styles.vitualCardPaper}>
        <FormStyled onSubmit={() => { }} style={{ opacity: loadingVirtualCards ? '0.3' : '1' }}>
          <DivFormTitle>
            <Typography
              style={{ ...styles.title, marginTop: 16 }}
              variant="h6"
            >
              DETALHES DO CARTÃO VIRTUAL
            </Typography>
          </DivFormTitle>
          {sonVirtualCards ? (
            <>
              {alertSuccessVirtualCard && (
                <Alert style={{ marginTop: '10px' }} severity="success" onClose={() => setAlertSuccessVirtualCard(false)}>
                  Operação concluída com sucesso!
                </Alert>
              )}
              {alertsVirtualCard && (
                <Alert style={{ marginTop: '10px' }} severity="error" onClose={() => setAlertsVirtualCard(false)}>
                  Não foi possível realizar a operação.
                </Alert>
              )}
              <FormControl fullWidth>
                <TextField
                  disabled
                  label="N. do cartão"
                  name="cartao"
                  value={sonVirtualCards.numeroCartao || '-'}
                  margin="normal"
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  disabled
                  label="ID"
                  name="cardId"
                  value={sonVirtualCards.idCartao || '-'}
                  margin="normal"
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  disabled
                  label="Status"
                  name="status"
                  value={getCardStatus(sonVirtualCards.idStatusCartao).label || '-'}
                  margin="normal"
                />
              </FormControl>
              {/* <FormControl fullWidth>
                <TextField
                  disabled
                  label="Emissão"
                  name="emissão"
                  value={format(parseISO(sonVirtualCards.dataValidade), 'dd/MM/yyyy', { locale: pt }) || '-'}
                  margin="normal"
                />
              </FormControl> */}
              <FormControl fullWidth>
                <TextField
                  disabled
                  label="Validade"
                  name="vencimento"
                  value={format(parseISO(sonVirtualCards.dataValidade), 'dd/MM/yyyy', { locale: pt }) || '-'}
                  margin="normal"
                />
              </FormControl>
              {/* <Button
                variant="outlined"
                style={{ marginTop: '10px', marginLeft: '10px' }}
                color="primary"
                // onClick={}
              >
                Solicitar Cartão Virtual
              </Button> */}
              {sonVirtualCards.idStatusCartao != null &&
                <Button
                  variant="outlined"
                  style={{ marginTop: '10px', marginLeft: '10px' }}
                  color="primary"
                  onClick={toggleOpenBlockVirtualCard}
                >
                  {getCardStatus(sonVirtualCards.idStatusCartao).label === 'Bloqueado'
                    ? 'Desbloquear'
                    : 'Bloquear'} cartão
                </Button>
              }
              <Button
                variant="outlined"
                style={{ marginTop: '10px', marginLeft: '10px' }}
                color="primary"
                onClick={toggleOpenCancelVirtualCard}
              >
                Cancelar cartão
              </Button>
            </>
          ) : (
            <DivFormTitle>
              <Typography
                style={{ ...styles.title, marginTop: 16 }}
                variant="h6"
              >
                Nenhum cartão virtual encontrado para o usuário!
              </Typography>
            </DivFormTitle>
          )}
        </FormStyled>
      </Paper>

      {/* Desbloquear/Bloquear cartão virtual */}
      {sonVirtualCards && getCardStatus(sonVirtualCards.idStatusCartao).value === 'blocked' &&
        <ConfirmDialog
          show={openBlockVirtualCard}
          proceed={() => handleRequestUnblockVirtualCard(sonVirtualCards.idCartao, sonVirtualCards.numeroCartao.substring(12))}
          dismiss={() => toggleOpenBlockVirtualCard()}
          cancel={() => toggleOpenBlockVirtualCard()}
          confirmation={`Deseja realizar o desbloqueio do cartão ${sonVirtualCards.numeroCartao}?`}
          proceedText="Solicitar desbloqueio"
          cancelText="Voltar"
          options={{
            title: 'Solicitar o desbloqueio cartão?',
            titleColor: '#777'
          }}
        />
      }

      {sonVirtualCards && getCardStatus(sonVirtualCards.idStatusCartao).value === 'normal' &&
        <ConfirmDialog
          show={openBlockVirtualCard}
          proceed={() => handleRequestBlockVirtualCard(sonVirtualCards.idCartao)}
          dismiss={() => toggleOpenBlockVirtualCard()}
          cancel={() => toggleOpenBlockVirtualCard()}
          confirmation={`Deseja realizar o bloqueio do cartão ${sonVirtualCards.numeroCartao}?`}
          proceedText="Solicitar bloqueio"
          cancelText="Voltar"
          options={{
            title: 'Solicitar o bloqueio cartão?',
            titleColor: '#777'
          }}
        />
      }

      {/* Cancelar cartão virtual*/}
      {sonVirtualCards &&
        <ConfirmDialog
          show={openCancelVirtualCard}
          proceed={() => handleRequestCancelVirtualCard(sonVirtualCards.idCartao)}
          dismiss={() => toggleOpenCancelVirtualCard()}
          cancel={() => toggleOpenCancelVirtualCard()}
          confirmation={`Deseja realizar o cancelamento do cartão ${sonVirtualCards.numeroCartao}?\nEsse processo não pode ser revertido!`}
          proceedText="Solicitar cancelamento"
          cancelText="Voltar"
          options={{
            title: 'Solicitar o cancelamento do cartao?',
            titleColor: '#777'
          }}
        />}

    </Container>
  );
}

export default FormCard;

const styles = {
  container: {
    position: 'relative'
  },
  title: {
    fontSize: style.fontSize
  },
  vitualCardPaper: {
    marginTop: 15
  }
}
