export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_GET_ME = 'AUTH_GET_ME';
export const AUTH_SELECT_TENANT = 'AUTH_SELECT_TENANT'

// ----- users ---------------------------------------------------------------------------------
export const REQUEST_USERS_START = 'REQUEST_USERS_START';
export const REQUEST_USERS_BY_ID_START = 'REQUEST_USERS_BY_ID_START';
export const REQUEST_SONS_BY_FATHER_ID_START = 'REQUEST_SONS_BY_FATHER_ID_START';
export const REQUEST_SON_CARDS_START = 'REQUEST_SON_CARDS_START';
export const REQUEST_SON_CARDS_LOADING_START = 'REQUEST_SON_CARDS_LOADING_START';
export const REQUEST_STATEMENT_START = 'REQUEST_STATEMENT_START';
export const REQUEST_PIX_START = 'REQUEST_PIX_START';
export const REQUEST_PIX_LIMIT_START = 'REQUEST_PIX_LIMIT_START';
export const REQUEST_PIX_KEYS_START = 'REQUEST_PIX_KEYS_START';
export const REQUEST_SON_ACCOUNT_START = 'REQUEST_SON_ACCOUNT_START';
export const REQUEST_TRACK_START = 'REQUEST_TRACK_START';
export const REQUEST_STATEMENT_INFINITE_SCROLL_START = 'REQUEST_STATEMENT_INFINITE_SCROLL_START';
export const REQUEST_PIX_INFINITE_SCROLL_START = 'REQUEST_PIX_INFINITE_SCROLL_START';
export const REQUEST_INVITE_START = 'REQUEST_INVITE_START';
export const REQUEST_STATS_START = 'REQUEST_STATS_START';
export const TRANSFER_START = 'TRANSFER_START';
export const REQUEST_CANCELACC_START = 'REQUEST_CANCELACC_START';
export const REQUEST_DELETE_START = 'REQUEST_DELETE_START';
export const FETCH_IMAGES_USER_START = 'FETCH_IMAGES_USER_START';
export const FETCH_IMAGES_USER_SUCCESS = 'FETCH_IMAGES_USER_SUCCESS';
export const FETCH_IMAGES_USER_FAIL = 'FETCH_IMAGES_USER_FAIL';
export const REQUEST_SON_VIRTUAL_CARDS_START = 'REQUEST_SON_VIRTUAL_CARDS_START';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const CHANGE_SPD_STATUS_START = 'CHANGE_SPD_STATUS_START';
export const CHANGE_SPD_STATUS_SUCCESS = 'CHANGE_SPD_STATUS_SUCCESS';
export const CHANGE_SPD_STATUS_FAIL = 'CHANGE_SPD_STATUS_FAIL';

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_BY_ID_SUCCESS = 'FETCH_USERS_BY_ID_SUCCESS';
export const FETCH_SONS_BY_FATHER_ID_SUCCESS = 'FETCH_SONS_BY_FATHER_ID_SUCCESS';
export const FETCH_SON_CARDS_SUCCESS = 'FETCH_SON_CARDS_SUCCESS';
export const FETCH_STATEMENT_SUCCESS = 'FETCH_STATEMENT_SUCCESS';
export const FETCH_PIX_SUCCESS = 'FETCH_PIX_SUCCESS';
export const FETCH_PIX_KEYS_SUCCESS = 'FETCH_PIX_KEYS_SUCCESS';
export const ADD_PIX_LIMIT_SUCCESS = 'ADD_PIX_LIMIT_SUCCESS';
export const FETCH_SON_ACCOUNT_SUCCESS = 'FETCH_SON_ACCOUNT_SUCCESS';
export const FETCH_TRACK_SUCCESS = 'FETCH_TRACK_SUCCESS';
export const FETCH_STATEMENT_INFINITE_SCROLL_SUCCESS = 'FETCH_STATEMENT_INFINITE_SCROLL_SUCCESS';
export const FETCH_PIX_INFINITE_SCROLL_SUCCESS = 'FETCH_PIX_INFINITE_SCROLL_SUCCESS';
export const FETCH_INVITE_SUCCESS = 'FETCH_INVITE_SUCCESS';
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS';
export const SEND_TRANSFER_SUCCESS = 'SEND_TRANSFER_SUCCESS';
export const FETCH_YOURSINFO_SUCCESS = 'FETCH_YOURSINFO_SUCCESS';
export const FETCH_CANCELACC_SUCCESS = 'FETCH_CANCELACC_SUCCESS';
export const FETCH_DELETE_SUCCESS = 'FETCH_DELETE_SUCCESS';
export const FETCH_SON_VIRTUAL_CARDS_SUCCESS = 'FETCH_SON_VIRTUAL_CARDS_SUCCESS';

export const REQUEST_USERS_FAIL = 'REQUEST_USERS_FAIL';
export const REQUEST_USERS_BY_ID_FAIL = 'REQUEST_USERS_BY_ID_FAIL';
export const REQUEST_SONS_BY_FATHER_ID_FAIL = 'REQUEST_SONS_BY_FATHER_ID_FAIL';
export const REQUEST_SON_CARDS_FAIL = 'REQUEST_SON_CARDS_FAIL';
export const REQUEST_STATEMENT_FAIL = 'REQUEST_STATEMENT_FAIL';
export const REQUEST_PIX_FAIL = 'REQUEST_PIX_FAIL';
export const REQUEST_PIX_KEYS_FAIL = 'REQUEST_PIX_KEYS_FAIL';
export const ADD_PIX_LIMIT_FAIL = 'ADD_PIX_LIMIT_FAIL';
export const REQUEST_SON_ACCOUNT_FAIL = 'REQUEST_SON_ACCOUNT_FAIL';
export const REQUEST_TRACK_FAIL = 'REQUEST_TRACK_FAIL';
export const REQUEST_INVITE_FAIL = 'REQUEST_INVITE_FAIL';
export const REQUEST_STATS_FAIL = 'REQUEST_STATS_FAIL';
export const TRANSFER_FAIL = 'TRANSFER_FAIL';
export const REQUEST_YOURSINFO_FAIL = 'REQUEST_YOURSINFO_FAIL';
export const FETCH_CANCELACC_FAIL = 'FETCH_CANCELACC_FAIL';
export const FETCH_DELETE_FAIL = 'FETCH_DELETE_FAIL';
export const REQUEST_SON_VIRTUAL_CARDS_FAIL = 'REQUEST_SON_VIRTUAL_CARDS_FAIL';

export const TRANSFER_CLEAR = 'TRANSFER_CLEAR';



// ----- cache ---------------------------------------------------------------------------------

export const REQUEST_CACHE_START = 'REQUEST_CACHE_START';
export const FETCH_CACHE_SUCCESS = 'FETCH_CACHE_SUCCESS';
export const FETCH_CACHE_FAIL = 'FETCH_CACHE_FAIL';
