import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  ListItemText
} from '@material-ui/core';


export default function ListItemTexts(props) {
  const classes = useStyles();

  return (
    <ListItemText
      primary={<span style={{ color: props.color, fontSize: '0.8rem' }}>{props.label}</span>}
      classes={{
        root: classes.root,
      }}
    />
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
  },
}));