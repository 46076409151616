import React, { useCallback, useEffect, useState } from "react";
import { Button, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot } from '@material-ui/lab';

import { ArrowBack, Payment } from "@material-ui/icons";

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Colors from "../../../constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import SpinnerOverlay from "../../../UI/SpinnerOverlay/SpinnerOverlay";
import { numberToCurrency } from "../../../utils/formatText";
import Budget from "../../../components/TopCard";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

const Extract = (props) => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const { yours } = useSelector(state => state.users);
    const { statement, loading } = useSelector(state => state.statement);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const handleBack = () => {
        props.history.goBack();
    }

    const fetchMore = useCallback(
        (page, limit, infiniteScroll) => dispatch(actions.fetchStatementYours(page, limit, infiniteScroll)),
        [page, limit]
    )

    useEffect(() => {
        fetchMore(page, limit, false);
    }, []);

    const handleChangeRowsPerPage = () => {
        setPage(page + 1)
        fetchMore(page + 1, limit, true);
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={handleBack} style={styles.arrowBack}><ArrowBack /></IconButton>
                <Typography>Extrato</Typography>
            </div>

            <Grid container justify="center">
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Budget
                        type="money"
                        text="Saldo Yours"
                        values={numberToCurrency(yours.Saldoyours)}
                    />
                </Grid>
            </Grid>

            <Grid container justify="center" >
                {loading && <SpinnerOverlay />}
                {statement.count < 1 && !loading ? (
                    <Grid justify="center" container>
                        <div style={styles.notFound}>nenhum extrato encontrado</div>
                    </Grid>
                ) : (
                    <>

                        <Grid style={{ width: '60%', margin: 'auto' }}>
                            <Timeline align="alternate">
                                {
                                    statement.rows.map((item) => (
                                        <TimelineItem>
                                            <TimelineOppositeContent>
                                                <Typography variant="body2" color="textSecondary">
                                                    {`Transação em ${moment(item.dataOrigem).format('DD/MM/YYYY')}`}
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot>
                                                    <Payment />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Paper elevation={3} className={classes.paper}>
                                                    <Typography variant="h6" component="h1">
                                                        {item.descricaoAbreviada}
                                                    </Typography>
                                                    <Typography style={{ fontSize: 16, color: 'grey' }}>{item.nomePortador}</Typography>
                                                    <Typography style={{ fontSize: 14, color: 'grey', fontWeight: 'bold' }} >
                                                        Valor: R${item.valorBRL}
                                                    </Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>

                                    ))
                                }
                            </Timeline>
                        </Grid>
                        {!loading ? (
                            <Grid
                                justify="center"
                                container
                            >
                                {
                                    statement.count == statement.rows.length ? null :
                                        <Button style={{ marginTop: 5, background: '#3B5998' }} onClick={handleChangeRowsPerPage} >
                                            <Typography style={{ color: '#fff' }}>Show More</Typography>
                                        </Button>
                                }
                            </Grid>
                        ) : <></>}
                    </>
                )
                }
            </Grid>
        </>
    );

}

export default Extract;

const styles = {
    container: {
        marginLeft: '5rem',
        marginBottom: '1rem',
    }
}