import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../store/actions';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import Login from '../containers/Login/Login';

const withSession = Component => props => {
  const dispatch = useDispatch();

	const token = localStorage.getItem('token');
	
  if (token) {
		return <Component {...props} />
	} else {
		return <Login />
	}
}

const styles = {
  container: {
    width: "100%"
  }
}

export default withSession;