import React from 'react';
import {
  Typography,
  IconButton,
  InputBase,
  Tooltip,
  Button,
  CircularProgress,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';

export default function TableTitle(props) {
  return (
    <div style={styles.container}>
      <div style={styles.titleRow}>
        <Typography variant='h6' style={{ padding: '8px 0' }}>{props.title}</Typography>
        <div>
					{props.showExportButton &&
						<IconButton
							aria-label="add"
							onClick={props.clickExport()}
							disabled={props.exportButtonDisabled || props.downloading}
						>
							{props.downloading ?
								<CircularProgress size={24} /> :
								<Tooltip title='Exportar Dados'>
									<GetAppIcon style={styles.icon} />
								</Tooltip>}
						</IconButton>
            // <Button
            //   style={styles.exportButton}
            //   variant="outlined"
            //   color="primary"
            //   onClick={props.clickExport()}
            //   disabled={props.exportButtonDisabled || props.downloading}
            //   startIcon={props.downloading ? <CircularProgress size={20} /> : <GetAppIcon />}
            // >
            //   Exportar
            // </Button>
          }
          {props.addRow &&
            <IconButton aria-label="add" style={styles.iconButton} onClick={props.addRow}>
              <Tooltip title='Adicionar'>
                <AddIcon style={styles.icon} />
              </Tooltip>
						</IconButton>
						// <Button
						// 	style={styles.addButton}
						// 	variant="contained"
						// 	color="inherit"
						// 	onClick={props.addRow}
						// 	startIcon={<Add />}
						// >
						// 	adicionar
						// </Button>
          }
        </div>
      </div>
      {(props.inputRef || props.placeholder) && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={styles.searchRow}>
          <SearchIcon style={styles.searchIcon} />
          <InputBase
            style={styles.search}
            placeholder={props.placeholder}
            inputRef={props.inputRef}
            value={props.value}
            onChange={props.onChange}
          />
        </div>
      </div>}
    </div>
  )
}

const styles = {
  container: {
    padding: '10px 20px',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  exportButton: {
    marginRight: 10,
  },
  searchRow: {
    // marginTop: '10px',
    maxWidth: '500px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    padding: '2px 4px',
    borderRadius: '5px',
  },
  iconButton: {
    marginRight: '-10px',
  },
  searchIcon: {
    color: '#ccc'
  },
  search: {
    flex: 1,
    marginLeft: '8px',
	},
	icon: {
		fontSize: '1.75rem',
	}
};
