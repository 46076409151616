import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../store/actions';

import {
  IconButton, Tabs, Tab, Typography, CircularProgress
} from '@material-ui/core';
import {
  ArrowBack,
} from '@material-ui/icons';

import FormFather from './FormsFather/FormFather';
import FormSons from './FormsFather/FormSons';
import FormStatement from './FormsFather/FormStatement';

import FormSon from './FormsSon/FormSon';
import FormSonStatement from './FormsSon/FormStatement';
import FormCard from './FormsSon/FormCard';

import FormPix from './FormPix';
import FormConfig from './FormConfig';

import Colors from '../../../../constants/Colors';
import FormImages from './FormImages';

const TabsUser = (props) => {
	const dispatch = useDispatch();
	const userId = props.match.params.id;

	const [value, setValue] = useState(0);

	const user = useSelector(state => state.users.user)

	const fetchUsersById = useCallback((id) => dispatch(actions.fetchUsersById(id)), [dispatch])

	useEffect(() => {
		fetchUsersById(userId);
	}, [fetchUsersById, userId]);

	useEffect(() => {
		setValue(0);
	}, [user]);

  const handleChange = (event, newValue) => {
		setValue(newValue);
  }

  const handleBack = () => {
		props.history.goBack();
  }

	const TabPanel = (TabProps) => {
		const { children, val, index, ...other } = TabProps;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`full-width-tabpanel-${index}`}
				aria-labelledby={`full-width-tab-${index}`}
				{...other}
			>
				{val === index && (
					children
				)}
			</div>
		);
	}

  return (
    <>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<IconButton onClick={handleBack} style={styles.arrowBack}><ArrowBack /></IconButton>
				<Typography>{`${user.name} (${user.role})`}</Typography>
			</div>

			{user.role === 'FATHER' &&
				<Tabs
					value={value}
					indicatorColor='secondary'
					textColor='secondary'
					onChange={handleChange}
					aria-label="disabled tabs example"
					style={styles.container}
				>
					<Tab label="Detalhes do Pai" />
					<Tab label="Filhos" />
					<Tab label="Extrato" />
					<Tab label="Pix" />
          <Tab label="Imagens Documentos" />
					<Tab label="Configurações" />
				</Tabs>}

			{user.role === 'SON' &&
				<Tabs
					value={value}
					indicatorColor='secondary'
					textColor='secondary'
					onChange={handleChange}
					aria-label="disabled tabs example"
					style={styles.container}
				>
					<Tab label="Detalhes do Filho" />
					<Tab label="Extrato" />
					<Tab label="Cartões" />
          <Tab label="Pix" />
					<Tab label="Imagens Documentos" />
          <Tab label="Configurações" />
				</Tabs>
			}

			{user.role !== 'FATHER' && user.role !== 'SON' &&
				<Tabs
					value={value}
					indicatorColor='secondary'
					textColor='secondary'
					onChange={handleChange}
					aria-label="disabled tabs example"
					style={styles.container}
				>
					<Tab label={<CircularProgress style={{ color: Colors.secondary }} size={20} />} />
				</Tabs>
			}

			{user.role === 'FATHER' ?
				<div>
					<TabPanel val={value} index={0}><FormFather /></TabPanel>
					<TabPanel val={value} index={1}><FormSons {...props} /></TabPanel>
					<TabPanel val={value} index={2}><FormStatement /></TabPanel>
					<TabPanel val={value} index={3}><FormPix /></TabPanel>
					<TabPanel val={value} index={4}><FormImages /></TabPanel>
					<TabPanel val={value} index={5}><FormConfig /></TabPanel>
				</div> :
				<div>
					<TabPanel val={value} index={0}><FormSon /></TabPanel>
					<TabPanel val={value} index={1}><FormSonStatement /></TabPanel>
					<TabPanel val={value} index={2}><FormCard /></TabPanel>
          <TabPanel val={value} index={3}><FormPix /></TabPanel>
					<TabPanel val={value} index={4}><FormImages /></TabPanel>
          <TabPanel val={value} index={5}><FormConfig /></TabPanel>
				</div>
			}
		</>
  )
}

export default TabsUser;

const styles = {
  arrowBack: {
    // position: 'fixed',
	},
	container: {
		marginLeft: '5rem',
		marginBottom: '1rem',
	}
}


