import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Payment from '@material-ui/icons/Payment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Grid, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: '6px 16px',
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
  }));

const FormStatement = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { user } = useSelector(state => state.users);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const fetchMore = useCallback(
    (idAccount, page, limit, infiniteScroll) => dispatch(actions.fetchStatement(idAccount, page, limit, infiniteScroll)),
    [dispatch]
  )

  const { statement } = useSelector(state => state.statement);

  useEffect(() => {
    fetchMore(user.idAccount, page, limit, page > 1);
  }, [fetchMore, limit, page, user.idAccount]);

  const handleChangeRowsPerPage = () => setPage(p => p + 1);

  return (
    <Grid>
      {statement.count < 1 ? (
        <Grid
          justify="center"
          container
        >
          <div style={styles.notFound}>nenhum extrato encontrado</div>
        </Grid>
      ) : (
        <>
          <Grid style={{ width: '60%', margin: 'auto'}}>
            <Timeline align="alternate">
              {statement.rows.map((item) => (
                <TimelineItem key={item.identificadorExterno}>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      Transação em {moment(item.dataOrigem).format('DD/MM/YYYY')}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                      <TimelineDot>
                      <Payment />
                      </TimelineDot>
                      <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      {item.descricaoAbreviada}
                    </Typography>
                    <Typography style={{ fontSize: 16, color: 'grey'}}>{item.nomePortador}</Typography>
                    <Typography style={{ fontSize: 14, color: 'grey', fontWeight: 'bold'}} >
                      Valor: R${item.valorBRL}
                    </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
          <Grid
            justify="center"
            container
          >
            <Button style={{ marginTop: 5, background: '#3B5998' }} onClick={handleChangeRowsPerPage} >
              <Typography style={{ color: '#fff' }}>Show More</Typography>
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  )
}

const styles = {
	notFound: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '1rem 0'
	}
}

export default FormStatement;
