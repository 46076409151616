import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom'
import * as actions from '../../store/actions';

import {
  Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box,
  Typography, Container,
} from '@material-ui/core';

import {
  LogoText, Appbar,
} from './styles';

import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../constants/Colors';
import SpinnerOverlay from '../../UI/SpinnerOverlay/SpinnerOverlay';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: window.innerHeight / 3.3333,
    display: 'flex',
    flexDirection: 'column',
		alignItems: 'center',
		height: '100%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    position: 'relative'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: Colors.primary,
    '&:hover': {
      backgroundColor: Colors.primary,
      opacity: .75,
    },
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    opacity: '0.5',
    zIndex: 2
  }
}));

const SignIn = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [noAccess, setNoAccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [form, setForm] = useState({ cpf: '', password: '' })
  const loading = useSelector(state => state.auth.loading)

  const changeValue = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value
    });
    setShowError(false);
  }

  useEffect(() => {
    localStorage.removeItem('token');
  }, [])

  const submit = async (event) => {
    event.preventDefault();
    setNoAccess(false);
		dispatch(actions.auth(form.cpf, form.password))
			.then((res) => {
				if (res.token) {
					props.history.push('/');
				}
			})
			.catch(() => {
				setShowError(true);
			})
  }

  return (
    <>
      <Appbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <LogoText />
          <form className={classes.form} noValidate>
            {loading && <div className={classes.overlay}></div>}
            {loading && <SpinnerOverlay />}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="cpf"
              label="CPF"
              name="cpf"
              autoComplete="CPF"
              autoFocus
              onChange={changeValue}
              value={form.cpf}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  submit(e);
                }
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={changeValue}
              value={form.password}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  submit(e);
                }
              }}
            />
            {showError && <div style={{ color: 'red' }}>Usuário ou senha incorretos</div>}
            {noAccess && <div style={{ color: 'red' }}>Você não possui autorização, fale com seu administrador</div>}

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submit}
              disabled={loading}
            >
              Sign In
            </Button>

          </form>
        </div>
      </Container>
    </>
  );
}

export default withRouter(SignIn)
