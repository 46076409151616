
export const phoneMask = phoneValue => {
  let phone = '';
  if(phoneValue.substr(1,1) === '0'){
    phone = phoneValue.replace(/\D/g, '').substring(1, 11);
  } else {
    phone = phoneValue.replace(/\D/g, '').substring(0, 11);
  }
  switch (phone.length) {
    case 2:
      return phone.replace(/\D/g, '').replace(/^(\d{2})(\d{5})?(\d{4})?/, "($1) ")
    case 5:
      return phone.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?/, "($1) $2")
    case 6:
      return phone.replace(/\D/g, '').replace(/^(\d{2})(\d{4})?/, "($1) $2-")
    case 10:
      return phone.replace(/\D/g, '').replace(/^(\d{2})(\d{4})?(\d{4})?/, "($1) $2-$3")
    case 11:
      return phone.replace(/\D/g, '').replace(/^(\d{2})(\d{5})?(\d{4})?/, "($1) $2-$3")
    default:
      return;
  }
}
