import styled from 'styled-components';

import { FormControl } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

export const FormStyled = styled.form`
  padding: 20px;
  overflow: visible;
`;

export const DoneIconStyled = styled(DoneIcon)`
  color: green !important;
`;
export const FormControlRadioStyled = styled(FormControl)`
  margin-left: 40px !important;
`;
export const DivSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: '10px 0';
`
export const DivSpinner = styled.div`
display: flex;
justify-content: center;
margin-top: 20px;
`;

export const DivFormTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
	padding-bottom: 0.5rem;
  /* border-bottom: 1px solid #ddd; */
`;

export const styleModal = {
  overflow: 'visible',
}

export const style = {
  fontSize: '1.1rem',
}

export const TopNavigationLabel = styled.div`
  
`;