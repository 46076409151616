import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Typography,
  Paper,
} from '@material-ui/core';
import * as actions from '../../../../store/actions';
import SpinnerOverlay from '../../../../UI/SpinnerOverlay/SpinnerOverlay';
import { FormStyled, DivFormTitle, style } from './styles';

const FormImages = () => {

  const dispatch = useDispatch();
  const { user, loadingImagesUser } = useSelector(state => state.users);
  const [images, setImages] = useState([])
  const [imageSelected, setImageSelected] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    dispatch(actions.fetchImagesUser(user._id)).then(res => {
      setImages(res.documents)
      setError('')
    }).catch(err => {
      setError('Usuário não finalizou o cadastro. Não é possível visualizar as imagens')
    });
  }, [dispatch, user._id])

  const modalSet = (image) => {
    setOpenModal(openModal => !openModal)
    setImageSelected(image)
  }

  return (
    <Container maxWidth='md' style={{ position: 'relative' }}>
      <Paper>
        <FormStyled>
          <DivFormTitle>
            <Typography style={{ fontSize: style.fontSize }} variant='h6'>Imagens Documentos</Typography>
          </DivFormTitle>
        </FormStyled>

        <FormStyled>
          <DivFormTitle>
            <Typography style={{ fontSize: style.fontSize, color: 'red' }} variant='h6'>{error}</Typography>
          </DivFormTitle>
        </FormStyled>

        {images.map(image => (
          <a onClick={() => modalSet(image.url)} style={{ cursor: 'pointer' }}>
            <span style={{ width: '200px;', height: '200px;', overflow: 'hidden', margin: '15px' }}>
              <img style={{ height: '250px' }} src={`data:image/png;base64,${image.url}`} />
            </span>
          </a>
        ))}

        {openModal &&
          <div onClick={() => setOpenModal(false)} style={{ width: '100%', height: '100%', background: 'rgb(0 0 0 / 50%)', position: 'fixed', right: 0, top: 0, zIndex: 10000 }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' }}>
              <img src={`data:image/png;base64,${imageSelected}`} />
            </div>
          </div>
        }
        {loadingImagesUser || error && <SpinnerOverlay />}
      </Paper>
    </Container>
  );
};

export default FormImages;
