import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";
import moment from 'moment';

import { Alert } from '@material-ui/lab';
import { FaWhatsapp } from 'react-icons/fa';
import {
	Grid, Paper, Typography, FormControl, Container, TextField, IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import {
	FormStyled, DivFormTitle, style,
} from '../styles';
import FormDialog from '../components/FormDialog';

import { numberToCurrency, currencyToNumber, onlyNumbers } from '../../../../../utils/formatText';
import SpinnerOverlay from '../../../../../UI/SpinnerOverlay/SpinnerOverlay';
import { emailValidator } from '../../../../../utils/emailValidator';
import { phoneMask } from '../../../../../utils/phoneValidator';
import { KeyboardDatePicker } from '@material-ui/pickers';

const FormSon = () => {

	const dispatch = useDispatch();
	const { user, loadingUser, loadingUpdateUser } = useSelector(state => state.users);
	const [success, setSuccess] = useState(false);
	const [errorEdit, setErrorEdit] = useState(false);
	const { status, error, statusAcc, errorC, errorD, statusD } = useSelector(state => state.account);
	const fetchSon = useCallback(() => dispatch(actions.fetchSonById(user._id)), []);
	const [edit, setEdit] = useState(false);

	const [email, setEmail] = useState(user.email);
	const [birthdate, setBirthdate] = useState(moment(user.birthDate).add(3, 'hours').format('MM/DD/YYYY'));
	const [phone, setPhone] = useState((!user.phone || Object.keys(user.phone).length === 0) ? '-'
		: `(${user.phone.areaCode})${user.phone.number}`);

	const toggleEdit = () => setEdit(o => !o);

	const saveEdit = () => {
		setEdit(o => !o)
		dispatch(actions.updateUser(user._id, email, onlyNumbers(phone), '', '', '', '', '', birthdate)).then(res => {
			dispatch(actions.fetchUsersById(user._id)).then(res => {
				setSuccess(true)
				setErrorEdit(false)
			})
		}).catch(err => {
			console.log('Ocorreu um erro', err)
			dispatch(actions.fetchUsersById(user._id)).then(res => {
				setErrorEdit(true)
				setSuccess(false)
			})
		})
	};

	useEffect(() => {
		fetchSon();
		dispatch(actions.transferClear())
	}, [dispatch, user]);

	return (
		<>
			<Container maxWidth='md' style={styles.container}>
				{loadingUser && loadingUpdateUser && <SpinnerOverlay />}
				<Paper>
					<FormStyled onSubmit={() => { }} style={{ opacity: loadingUser ? '0.3' : '1' }}>
						<DivFormTitle>
							<Typography style={{ ...styles.title, minHeight: 50, paddingTop: 12 }} variant='h6'>DETALHES DO FILHO</Typography>

							{!edit &&
								<IconButton
									color="inherit"
									aria-label="Edit"
									onClick={() => toggleEdit()}
									edge="edit"
								>
									<EditIcon />
								</IconButton>
							}
							{edit &&
								<>
									<IconButton
										color="inherit"
										aria-label="Cancel"
										onClick={() => toggleEdit()}
										edge="cancel"
										style={{ position: 'absolute', right: '12%', top: '2%', color: 'red' }}
									>
										<CancelIcon />
									</IconButton>
									<IconButton
										color="inherit"
										aria-label="Save"
										onClick={() => saveEdit()}
										edge="save"
										style={{ position: 'absolute', right: '4%', top: '2%', color: 'green' }}
									>
										<SaveIcon />
									</IconButton>
								</>
							}
						</DivFormTitle>

						{status || statusAcc || statusD || success ?
							<Alert style={{ marginTop: '10px' }}
								severity="success"
								onClose={() => dispatch(actions.transferClear())}>
								Operação realizada com sucesso!
							</Alert> : null}

						{error || errorC || errorD || errorEdit ?
							<Alert style={{ marginTop: '10px' }} severity="error"
								onClose={() => dispatch(actions.transferClear())}>
								Não foi possível realizar a operação
							</Alert> : null}

						<>
							<FormControl fullWidth>
								<TextField
									disabled
									label='idAccount'
									name='account'
									// onChange={event => setName(event.target.value)}
									value={user.idAccount ? user.idAccount : '-'}
									margin='normal' />
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled
									label='idIndividual'
									name='individual'
									// onChange={event => setName(event.target.value)}
									value={user.idIndividual ? user.idIndividual : '-'}
									margin='normal' />
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled
									label='Saldo'
									name='saldo'
									// onChange={event => setName(event.target.value)}
									value={user.saldoDisponivel ? numberToCurrency(user.saldoDisponivel) : '-'}
									margin='normal' />
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled
									label='Nome'
									name='nome'
									// onChange={event => setName(event.target.value)}
									value={user.name}
									margin='normal' />
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled
									label='CPF'
									name='cpf'
									// onChange={event => setCpf(event.target.value)}
									value={user.cpf}
									margin='normal'
								/>
							</FormControl>
							<FormControl fullWidth>
								{/* <TextField
                  disabled
									label='Data de Nascimento'
									name='birth'
									onChange={event => setBirthdate(event.target.value)}
									value={birthdate}
									margin='normal'
									/> */}

								<KeyboardDatePicker
									disabled={!edit}
									label='Data de Nascimento'
									name='birth'
									value={moment(user.birthDate).add(3, 'hours').format('MM/DD/YYYY')}
									onChange={date => moment(user.birthDate).add(3, 'hours').format('MM/DD/YYYY')}
									maxDate={new Date()}
									format="dd/MM/yyyy"
								/>
							</FormControl>
							<FormControl fullWidth>
								<TextField
									disabled={!edit}
									label='E-mail'
									name='email'
									onChange={event => setEmail(event.target.value)}
									value={email}
									margin='normal' />
								{!emailValidator(email) &&
									<div style={{ color: 'red' }}>Email inválido!</div>
								}
							</FormControl>
							<FormControl fullWidth>
								<Grid container direction="row" alignItems="center" justify="space-between">
									<TextField
										disabled={!edit}
										label='Celular'
										name='telefones'
										onChange={event => setPhone(event.target.value)}
										value={phoneMask(phone)}
										type='phone'
										margin='normal'
										style={{ width: '100%' }}
									/>
									{
										!user.phone || Object.keys(user.phone).length === 0 ?
											(
												<></>
											) :
											(
												<a
													href={`https://wa.me/${user.phone.number ?
														`+55${user.phone.areaCode}${user.phone.number}`
														: '-'}?text=Ol%C3%A1%2C%20${user.name}%20aqui%20quem%20fala%20%C3%A9%20a%20equipe%20da%20YoursBank`}
													style={{ position: 'absolute', marginLeft: '95%' }}
													target="_blank"
													rel="noopener noreferrer"
												>
													<FaWhatsapp fontSize={35} />
												</a>
											)
									}
								</Grid>
							</FormControl>
						</>

					</FormStyled>
				</Paper>
			</Container>
		</>
	)
}

export default FormSon;

const styles = {
	container: {
		position: 'relative'
	},
	title: {
		fontSize: style.fontSize,
	},
}


