import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Payment from '@material-ui/icons/Payment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Grid, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: '.3rem 1rem',
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
  }));

const TrackCard = ({ cardId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { track } = useSelector(state => state.users);

  useEffect(() => {
    dispatch(actions.fetchTrackCard(cardId))
  }, [dispatch, cardId]);

  return (
    <Grid style={{ marginTop: '2.5rem' }}>
      <Typography style={{ fontSize: 24, color: 'grey', fontWeight: 'bold' }}>
        Acompanhar cartão
      </Typography>

      {!track.response ? (
        <Grid justify="center" container>
          <div style={styles.notFound}>nenhum cartão encontrado</div>
        </Grid>
      ) : (
        <>
          <Grid style={{ width: '100%', margin: 'auto'}}>
            <Timeline align="alternate">
              {track.response.data.map((item, i) => (
                <TimelineItem key={i}>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      {`${moment(item.momento_log).format('DD/MM/YYYY h:mm:ss')}`}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot>
                      <Payment />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography component="h1">
                        {item.status.toUpperCase()}
                      </Typography>
                      <Grid container direction="column">
                        <Grid style={{ marginTop: '.625rem' }} >
                          <Typography component="span" style={{fontWeight: 'bold'}}>
                            Cod. Cliente:
                          </Typography>
                          <Typography style={{ marginTop: '.625rem' }} component="span">
                            {` ${item.codcliente}`}
                          </Typography>
                        </Grid>
                        <Divider />

                        <Grid style={{ marginTop: '.625rem' }} >
                          <Typography component="span" style={{fontWeight: 'bold'}}>
                            Cod. Entrega:
                          </Typography>
                          <Typography style={{ marginTop: '.625rem' }} component="span">
                            {` ${item.codentrega}`}
                          </Typography>
                        </Grid>
                        <Divider />

                        <Grid style={{ marginTop: '.625rem' }} >
                          <Typography component="span" style={{fontWeight: 'bold'}}>
                            Cod. EntregaCli:
                          </Typography>
                          <Typography style={{ marginTop: '.625rem' }} component="span">
                            {` ${item.codentregacli}`}
                          </Typography>
                        </Grid>
                        <Divider />

                        <Grid style={{ marginTop: '.625rem' }} >
                          <Typography component="span" style={{fontWeight: 'bold'}}>
                            Situação:
                          </Typography>
                          <Typography style={{ marginTop: '.625rem' }} component="span">
                            {` ${item.situacaoentrega}`}
                          </Typography>
                        </Grid>
                        <Divider />

                        <Grid style={{ marginTop: '.625rem' }} >
                          <Typography component="span" style={{fontWeight: 'bold'}}>
                            Cod. Correios:
                          </Typography>
                          <Typography style={{ marginTop: '.625rem' }} component="span">
                            {` ${item.codentregacorreio}`}
                          </Typography>
                        </Grid>
                        <Divider />

                        <Grid style={{ marginTop: '.625rem' }} >
                          <Typography component="span" style={{fontWeight: 'bold'}}>
                            Cod. Cidade:
                          </Typography>
                          <Typography style={{ marginTop: '.625rem' }} component="span">
                            {` ${item.cidade ? item.cidade : 'Não informado'}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
        </>
      )}
    </Grid>
  );
}

const styles = {
	notFound: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '1rem 0'
	}
}

export default TrackCard;
