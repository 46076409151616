import React, { useState } from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Divider,
  Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { ChevronRight, ChevronLeft } from '@material-ui/icons';

import withSession from '../../HOC/withSession';
import * as actions from '../../store/actions'

import Colors from '../../constants/Colors';
import ListItems from './ListItems/ListItems';
import Usuario from '../Usuario';

import logo from '../../assets/logo-yours-bank.png';

const drawerWidth = 240;

function NavBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    dispatch(actions.logout());
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    history.push('/login')
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          <NavLink className={classes.title} to={"/"}>
            <img src={logo} className={classes.logo} alt="Yours bank logo" />
          </NavLink>

          <Button color="inherit" onClick={handleLogout}>Sair</Button>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setOpen(false)}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />

        <ListItems />

        <Divider />

      </Drawer>

      <main
        style={{ backgroundColor: 'rgb(250, 250, 250)' }}
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })} >
        <div className={classes.drawerHeader} />

        <Usuario />

      </main>
    </div >
  );
}

// export default NavBar;
export default withSession(NavBar);

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    width: '100%',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  root: {
    // display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: Colors.secondary,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  menuLabelMobile: {
    padding: '0 10px',
  },
  navlink: {
    color: '#333',
    textDecoration: 'none',
  },
  avatar: {
    width: 70,
    height: 70,
  },
  logo: {
    width: '4.5rem',
  },
  link: {
    textDecoration: 'none',
    color: '#010',
  },
}));
