import API from '../../services/api';

import axios from 'axios';
let CancelToken = axios.CancelToken;
let fetchUsers_cancel;

export const fetchPush = (title, msg, type) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            API.post('push_notification/send', { title, msg, type }, {
                cancelToken: new CancelToken(function executor(c) {
                    fetchUsers_cancel = c;
                })
            })
                .then(res => resolve(res.data))
                .catch(err => reject(err));
        });
    }
};