import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';

const initialState = {
  openDialog: false,
  loading: false,
  error: null,
  status: null,

  openDialogCancel: false,
  loadingCancel: false,
  errorC: null,
  statusAcc: null,

  openDialogDelete: false,
  loadingDelete: false,
  errorD: null,
  statusD: null,
};

const transferStart = (state, action) => {
  return updateObject(state, { 
    error: null, 
    loading: true, 
    openDialog: true,
    status: null,
  })
}

const sendTransferSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    openDialog: false,
    status: true,
  });
};

const transferFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    openDialog: false,
    status: false,
  })
}

const transferClear = (state, action) => {
  return updateObject(state, { 
    error: null, 
    loading: false, 
    status: null,
    statusAcc: null,
    errorC: null,
    errorD: null,
    statusD: null,
  })
}

const requestCancelAccountStart = (state, action) => {
  return updateObject(state, { 
    errorC: null, 
    loadingCancel: true, 
    openDialogCancel: true,
    statusAcc: null,
  })
}

const requestAccountCancelSuccess = (state, action) => {
  return updateObject(state, {
    errorC: null,
    loadingCancel: false,
    openDialogCancel: false,
    statusAcc: true,
  });
};

const requestAccountCancelFail = (state, action) => {
  return updateObject(state, {
    errorC: action.error,
    loadingCancel: false,
    openDialogCancel: false,
    statusAcc: false,
  })
}

const requestDeleteStart = (state, action) => {
  return updateObject(state, { 
    openDialogDelete: true,
    loadingDelete: true,
    errorD: null,
    statusD: null,
  })
}

const requestDeleteSuccess = (state, action) => {
  return updateObject(state, {
    openDialogDelete: false,
    loadingDelete: false,
    errorD: null,
    statusD: true,
  });
};

const requestDeleteFail = (state, action) => {
  return updateObject(state, {
    errorC: action.error,
    loadingCancel: false,
    openDialogCancel: false,
    statusAcc: false,

    openDialogDelete: false,
    loadingDelete: false,
    errorD: action.error,
    statusD: false,
  })
}

// const requestAccountCancelClear = (state, action) => {
//   return updateObject(state, { 
//     error: null, 
//     loading: false, 
//     status: null,
//   })
// }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRANSFER_START: return transferStart(state, action);
    case actionTypes.SEND_TRANSFER_SUCCESS: return sendTransferSuccess(state, action);
    case actionTypes.TRANSFER_FAIL: return transferFail(state, action);
    case actionTypes.TRANSFER_CLEAR: return transferClear(state, action);

    case actionTypes.REQUEST_CANCELACC_START: return requestCancelAccountStart(state, action);
    case actionTypes.FETCH_CANCELACC_SUCCESS: return requestAccountCancelSuccess(state, action);
    case actionTypes.FETCH_CANCELACC_FAIL: return requestAccountCancelFail(state, action);

    case actionTypes.REQUEST_DELETE_START: return requestDeleteStart(state, action);
    case actionTypes.FETCH_DELETE_SUCCESS: return requestDeleteSuccess(state, action);
    case actionTypes.FETCH_DELETE_FAIL: return requestDeleteFail(state, action);

    default: return state;
  }
};

export default reducer;