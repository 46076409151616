import axios from 'axios';

const selectUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
			return 'http://yours-backend-dev.us-east-1.elasticbeanstalk.com/api';
    case 'prod':
   		return 'https://yours-api.yoursbank.com.br/api';
    default:
      return 'http://192.168.0.101:3000/api';
  }
};

const api = axios.create({
  baseURL: selectUrl(),
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');

  try {
    if (token) {
      config.headers.Authorization = token;
		}

    return config;
  } catch (err) {
    console.log('err', err)
  }
});


export default api;



