import Colors from '../constants/Colors';

export const getStatusFather = (invites, sons, status, permissionCPF, hasDocumentError, missingSPDValidation) => {
	if (!permissionCPF) {
		return 'cpfNotPermitted'
	} else if (hasDocumentError) {
		return 'accountDocumentsError'
	} else if (missingSPDValidation) {
		return 'missingSPDValidation'
	} else if (status !== 'ACTIVE') {
		return 'accountNotCreated'
	} else if (status === 'ACTIVE' && invites.count === 0 && sons.length === 0) {
		return 'sonsNotInvited'
	} else if (status === 'ACTIVE' && (invites.count > 0 || sons.length > 0)) {
		return 'sonsInvitedOrCreated'
	} else {
		return 'statusFail'
	}
}

export const getCardStatus = (status) => {
  if(status === 1) return { value: 'normal', label: 'Ativo' };
  if(status === 2
    || status === 25
    || status === 26
    || status === 29
    || status === 37) return { value: 'blocked', label: 'Bloqueado' };
  if(status <= 17 || status === 28 || status === 30) return { value: 'canceled', label: 'Cancelado' };
  if(status <= 19) return { value: 'migrated', label: 'Migrado' };
  if(status <= 24) return { value: 'canceledDef', label: 'Cancelado definitivamente' };
  if(status === 27) return { value: 'reissued', label: 'Reemitido' };
  if(status === 32) return { value: 'fraud', label: 'Suspeita de fraude' };
  if(status === 171) return { value: 'moneyLaundering', label: 'Prevenção a lavagem de dinheiro' };
  return { value: '' , label: '-' };
}

export const getCardStatusColor = (status) => {
  switch(status) {
    case 'fraud':
    case 'moneyLaundering':
    case 'blocked':
      return Colors.error;

    case 'canceled':
    case 'canceledDef':
      return Colors.disabled;

    default:
      return '#000';
  }
}

// idStatus	Description
// 1	Normal - Unblocked	Yes
// 2	Blocked - Can be reversed	Yes
// 3	Cancelled	No
// 4	Cancelled (Loss)	Yes
// 5	Cancelled (Theft)	Yes
// 6	Cancelled (Client)	No
// 7	Cancelled (Bank)	Yes
// 8	Cancelled (Misplaced)	Yes
// 9	Cancelled (Damaged)	Yes
// 10	Cancelled (Stripe)	Yes
// 11	Cancelled (Embossing)	Yes
// 12	Cancelled (Suspected false)	Yes
// 13	Cancelled (Post Office Return)	Yes
// 14	Cancelled (Cardholder Death)	Yes
// 15	Cancelled (Deactivated)	Yes
// 16	Cancelled (Expired)	Yes
// 17	Cancelled (Left in a Store)	Yes
// 18 - 19	Migrated	Yes
// 20 - 24	Cancelled - Definitive	Yes
// 25 - 26	Migrated Blocked	Yes
// 27	Reissued	Yes
// 28	Cancelled Definitive Reissued	Yes
// 29	Locked Incorrect Password - After 3 wrong attempts, the cardholder has their card locked	Yes
// 30	Cancelled - Not generated CVV/CVV2	Yes
// 32	Suspected Fraud - Preventive	No
// 37	Blocked - Preventive	No
// 171	Money Laundering Prevention	No

// idEstagioCartao/idEstagio	Description	Observation	Allows card cancellation?
// 1	Created.	Embossing generation pending (exits processing).	No
// 2	Sent to embossing.	Embossing generation pending (exits processing - for PNB).	No
// 3	Received.	Not used.	No
// 4	Forwarded.	Generated in embossing file - no longer pending, no exiting processing.	No
// 5	Unblocked with Code.	Card already unlocked by end customer via app / website	Yes
// 6	Unblocked without Code.	Card already unlocked by Conductor or call center (no code / password)	Yes
// 7	Unblocked informed.	Not used.	Yes
// 8	Reviewed.	Not used.	Yes
// 9	To be collected.	Not used.	Yes
// 10	Collected.	Used to prevent embossing, does not exit on processing.	Yes
// 11	Misplaced.	Usually used by the call center to reissue a route.	Yes
// 12	Embossing - Preview.	First stage of processing. Preview done, but not yet generated the file.	No
// 13	Embossing - Inconsistent.	Inconsistency.	No
// 14	Embossing - Inconsistency Released.	Inconsistency released (used).	No
// 15	Embossing - Resolved Inconsistency.	Resolved Inconsistency.	No

export const getLastCard = (cards) => {
	return cards.length > 0 && cards.reduce((prev, curr) => (prev.id > curr.id) ? prev : curr)
}

export const getStatusSon = (cards) => {
	const card = getLastCard(cards)

	if (cards.count === 0 || (card.idStatus >= 3 && card.idStatus <= 17) || (card.idStatus >= 20 && card.idStatus <= 24)) {
		return {
			status: 'noCardsFound',
			strStatus: 'Nenhum Cartão Ativo'
		}
	} else if (card.idEstagioCartao === 1 || card.idEstagioCartao === 2 || card.idEstagioCartao === 3 || card.idEstagioCartao === 4) {
		return {
			...card,
			status: 'cardAtEmbosser',
			strStatus: 'Cartão Em Processamento',
		}
	} else if (card.idStatus === 1) {
		return {
			...card,
			status: 'cardActive',
			strStatus: 'Cartão Ativo',
		}
	} else if (card.idStatus === 2) {
		return {
			...card,
			status: 'cardBlocked',
			strStatus: 'Cartão Não Ativado',
		}
	} else if (card.idStatus === 29) {
		return {
			...card,
			status: 'cardBlockedPassword',
			strStatus: 'Cartão Bloqueado',
		}
	} else {
		return {
			...card,
			status: 'none',
			strStatus: '...',
		}
	}
}

export const getCardEstagio = (card) => {
	switch (card.idEstagioCartao) {
		case 1: return { value: 'Created', label: 'Cartão Criado' };
		case 2: return { value: 'Sent to embossing', label: 'Cartão em processo de produção' };
		case 3: return { value: 'Received', label: 'Cartão sendo produzido' };
		case 4: return { value: 'Forwarded', label: 'Cartão enviado para a transportadora' };
		case 5: return { value: 'Unblocked with Code', label: 'Cartão desbloqueado pelo usuário com pin' };
		case 6: return { value: 'Unblocked without Code', label: 'Cartão desbloqueado sem pin' };
		case 7: return { value: 'Unblocked informed', label: 'Cartão desbloqueado' };
		case 8: return { value: 'Reviewed', label: 'Cartão Inspecionado' };
		case 9: return { value: 'To be collected', label: 'A ser coletado' };
		case 10: return { value: 'Collected', label: 'Coletado' };
		case 11: return { value: 'Misplaced', label: 'Cartão Extraviado' };
		case 12: return { value: 'Embossing - Preview', label: 'First stage of processing. Preview done, but not yet generated the file' };
		case 13: return { value: 'Embossing - Inconsistent.	Inconsistency', label: '' };
		case 14: return { value: 'Embossing - Inconsistency Released', label: 'Inconsistency released (used)' };
		case 15: return { value: 'Embossing - Resolved Inconsistency', label: 'Resolved Inconsistency' };

		default: break;
	}
}
