import React, { useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import Login from './containers/Login/Login';
import Navbar from './components/Navbar/Navbar';

const Routes = () => {
  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/" component={Navbar} />
    </Switch>
  );
}

export default Routes;
