import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';

const initialState = {
  users: {
    count: 0, rows: [],
  },
  imagesUser: [],
  stats: {
    filterCount: '...',
    FatherWithoutSon: '...',
    SonWithoutFather: '...',
    SonWithCard: '...',
    SonWithoutCard: '...',
    CardsNormal: '...',
    CardsBlocked: '...',
    CardsCancelled: '...',
  },
  yours: {
    Saldoyours: '...',
  },
  user: {},
  sons: { count: 0, rows: [] },
  sonCards: { name: '', cpf: '', cards: [] },
  sonVirtualCards: null,
  accounts: { count: 0, rows: [] },
  cache: { page: 1, limit: 5, order: 'asc', search: '', orderBy: 'name' },
  invites: { count: 0, rows: [] },
  track: {},
  selectedUserId: '',
  loading: false,
  loadingUser: false,
  loadingSons: false,
  loadingCards: false,
  loadingVirtualCards: false,
  loadingAcc: false,
  loadingTrack: false,
  loadingStats: false,
  loadingImagesUser: false,
  loadingUpdateUser: false,
  openDialogChangeSpdStatus: false,
};

const requestUsersStart = (state, action) => {
  return updateObject(state, { loading: true });
};
const requestUsersByIdStart = (state, action) => {
  return updateObject(state, { loadingUser: true });
};
const requestSonsByFatherIdStart = (state, action) => {
  return updateObject(state, { track: {}, loadingSons: true });
};
const requestSonCardsStart = (state, action) => {
  return updateObject(state, { sonCards: { name: '', cpf: '', cards: [] }, loadingCards: true });
};
const requestSonVirtualCardsStart = (state, action) => {
  return updateObject(state, { sonVirtualCards: null, loadingVirtualCards: true });
};
const requestSonCardsLoadingStart = (state, action) => {
  return updateObject(state, { loadingCards: true, sonVirtualCards: null });
};
const requestSonAccountStart = (state, action) => {
  return updateObject(state, { loadingAcc: true });
};
const requestTrackStart = (state, action) => {
  return updateObject(state, { track: {}, loadingTrack: true });
};
const requestCacheStart = (state, action) => {
  return updateObject(state);
};
const requestInviteStart = (state, action) => {
  return updateObject(state);
};
const requestStatsStart = (state, action) => {
  return updateObject(state);
}
const fetchImagesUserStart = (state, action) => {
  return updateObject(state, { loadingImagesUser: true });
};
const changeSpdStatusStart = (state, action) => {
  return updateObject(state, {
    openDialogChangeSpdStatus: true,
    loadingUpdateUser: true,
  });
}
const updateUserStart = (state, action) => {
  return updateObject(state, {
    loadingUpdateUser: true
  });
}

const fetchUsersSuccess = (state, action) => {
  return updateObject(state, {
    users: action.users,
    loading: false
  });
};
const fetchUsersByIdSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    loadingUser: false
  });
};
const fetchSonsByFatherIdSuccess = (state, action) => {
  return updateObject(state, {
    sons: action.sons,
    loadingSons: false
  });
};
const fetchSonCardsSuccess = (state, action) => {
  return updateObject(state, {
    sonCards: action.cards,
    loadingCards: false
  });
};
const fetchSonVirtualCardsSuccess = (state, action) => {
  return updateObject(state, {
    sonVirtualCards: action.cards,
    loadingVirtualCards: false,
    loadingCards: false
  });
};
const fetchSonAccountSuccess = (state, action) => {
  return updateObject(state, {
    accounts: action.accounts,
    loadingAcc: false,
  });
};
const fetchTrackSuccess = (state, action) => {
  return updateObject(state, {
    track: action.track,
    loadingAcc: false,
  });
};
const fetchCacheSuccess = (state, action) => {
  return updateObject(state, {
    cache: action.cache,
  });
};
const fetchInviteSuccess = (state, action) => {
  return updateObject(state, {
    invites: action.invites,
  });
};
const fetchStatsSuccess = (state, action) => {
  return updateObject(state, {
    stats: action.stats,
    loadingStats: false
  });
}
const fetchYoursInfoSuccess = (state, action) => {
  return updateObject(state, {
    yours: { Saldoyours: action.data },
    loadingStats: false
  });
};
const fetchImagesUserSuccess = (state, action) => {
  return updateObject(state, {
    imagesUser: action.data.result,
    loadingImagesUser: false
  });
};

const changeSpdStatusSuccess = (state, action) => {
  return updateObject(state, {
    openDialogChangeSpdStatus: false,
    loadingUpdateUser: false,
  });
};
const updateUserSuccess = (state, action) => {
  return updateObject(state, {
    loadingUpdateUser: false
  });
};
const requestUsersFail = (state, action) => {
  return updateObject(state, { loading: false });
};
const requestUsersByIdFail = (state, action) => {
  return updateObject(state, { loadingUser: false });
};
const requestSonsByFatherIdFail = (state, action) => {
  return updateObject(state, { sons: {}, loadingSons: false });
};
const requestSonCardsFail = (state, action) => {
  return updateObject(state, { sonCards: { name: '', cpf: '', cards: [] }, loadingCards: false });
};
const requestSonVirtualCardsFail = (state, action) => {
  return updateObject(state, { sonVirtualCards: null, loadingVirtualCards: false, loadingCards: false });
};
const requestSonAccountFail = (state, action) => {
  return updateObject(state, { loadingAcc: false });
};
const requestTrackFail = (state, action) => {
  return updateObject(state, { loadingTrack: false });
};
const requestCacheFail = (state, action) => {
  return updateObject(state);
};
const requestInvitesFail = (state, action) => {
  return updateObject(state);
};
const requestStatsFail = (state, action) => {
  return updateObject(state, { loading: false });
}
const requestYoursInfoFail = (state, action) => {
  return updateObject(state, { loading: false });
}
const requestImagesUserFail = (state, action) => {
  return updateObject(state, { loading: false });
}
const changeSpdStatusFail = (state, action) => {
  return updateObject(state, { openDialogChangeSpdStatus: false, loadingUpdateUser: false, });
}
const updateUserFail = (state, action) => {
  return updateObject(state, { loading: false });
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_USERS_START: return requestUsersStart(state, action);
    case actionTypes.REQUEST_USERS_BY_ID_START: return requestUsersByIdStart(state, action);
    case actionTypes.REQUEST_SONS_BY_FATHER_ID_START: return requestSonsByFatherIdStart(state, action);
    case actionTypes.REQUEST_SON_CARDS_START: return requestSonCardsStart(state, action);
    case actionTypes.REQUEST_SON_CARDS_LOADING_START: return requestSonCardsLoadingStart(state, action);
    case actionTypes.REQUEST_SON_ACCOUNT_START: return requestSonAccountStart(state, action);
    case actionTypes.REQUEST_TRACK_START: return requestTrackStart(state, action);
    case actionTypes.REQUEST_CACHE_START: return requestCacheStart(state, action);
    case actionTypes.REQUEST_INVITE_START: return requestInviteStart(state, action);
    case actionTypes.REQUEST_STATS_START: return requestStatsStart(state, action);
    case actionTypes.FETCH_IMAGES_USER_START: return fetchImagesUserStart(state, action);
    case actionTypes.REQUEST_SON_VIRTUAL_CARDS_START: return requestSonVirtualCardsStart(state, action);

    case actionTypes.FETCH_USERS_SUCCESS: return fetchUsersSuccess(state, action);
    case actionTypes.FETCH_USERS_BY_ID_SUCCESS: return fetchUsersByIdSuccess(state, action);
    case actionTypes.FETCH_SONS_BY_FATHER_ID_SUCCESS: return fetchSonsByFatherIdSuccess(state, action);
    case actionTypes.FETCH_SON_CARDS_SUCCESS: return fetchSonCardsSuccess(state, action);
    case actionTypes.FETCH_SON_ACCOUNT_SUCCESS: return fetchSonAccountSuccess(state, action);
    case actionTypes.FETCH_TRACK_SUCCESS: return fetchTrackSuccess(state, action);
    case actionTypes.FETCH_CACHE_SUCCESS: return fetchCacheSuccess(state, action);
    case actionTypes.FETCH_INVITE_SUCCESS: return fetchInviteSuccess(state, action);
    case actionTypes.FETCH_STATS_SUCCESS: return fetchStatsSuccess(state, action);
    case actionTypes.FETCH_YOURSINFO_SUCCESS: return fetchYoursInfoSuccess(state, action);
    case actionTypes.FETCH_IMAGES_USER_SUCCESS: return fetchImagesUserSuccess(state, action);
    case actionTypes.FETCH_SON_VIRTUAL_CARDS_SUCCESS: return fetchSonVirtualCardsSuccess(state, action);

    case actionTypes.REQUEST_USERS_FAIL: return requestUsersFail(state, action);
    case actionTypes.REQUEST_USERS_BY_ID_FAIL: return requestUsersByIdFail(state, action);
    case actionTypes.REQUEST_SONS_BY_FATHER_ID_FAIL: return requestSonsByFatherIdFail(state, action);
    case actionTypes.REQUEST_SON_CARDS_FAIL: return requestSonCardsFail(state, action);
    case actionTypes.REQUEST_SON_ACCOUNT_FAIL: return requestSonAccountFail(state, action);
    case actionTypes.REQUEST_TRACK_FAIL: return requestTrackFail(state, action);
    case actionTypes.FETCH_CACHE_FAIL: return requestCacheFail(state, action);
    case actionTypes.REQUEST_INVITE_FAIL: return requestInvitesFail(state, action);
    case actionTypes.REQUEST_STATS_FAIL: return requestStatsFail(state, action);
    case actionTypes.REQUEST_YOURSINFO_FAIL: return requestYoursInfoFail(state, action);
    case actionTypes.FETCH_IMAGES_USER_FAIL: return requestImagesUserFail(state, action);
    case actionTypes.REQUEST_SON_VIRTUAL_CARDS_FAIL: return requestSonVirtualCardsFail(state, action);

    case actionTypes.UPDATE_USER_START: return updateUserStart(state, action);
    case actionTypes.UPDATE_USER_SUCCESS: return updateUserSuccess(state, action);
    case actionTypes.UPDATE_USER_FAIL: return updateUserFail(state, action);
    case actionTypes.CHANGE_SPD_STATUS_START: return changeSpdStatusStart(state, action);
    case actionTypes.CHANGE_SPD_STATUS_SUCCESS: return changeSpdStatusSuccess(state, action);
    case actionTypes.CHANGE_SPD_STATUS_FAIL: return changeSpdStatusFail(state, action);
    default: return state;
  }
};

export default reducer;
