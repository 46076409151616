/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions";
import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';

import {
  Grid, Paper, Table, TableBody, TableCell, TableFooter, TablePagination, TableRow, Typography, CircularProgress
} from '@material-ui/core';
import { capitalize, numberToCurrency } from '../../../../utils/formatText'

import EnhancedTableHead from "../../../../UI/MaterialTableOverrides/EnhancedTableHead";
import TablePaginationActionsWrapped from "../../../../UI/MaterialTableOverrides/TablePaginationActions";
import TableTitle from '../../../../UI/MaterialTableOverrides/TableTitle';
import Colors from "../../../../constants/Colors";
import Budget from "../../../../components/TopCard";

const TableUsers = (props) => {
  const dispatch = useDispatch();
  let textInput = useRef(null);

  const headRows = [
    { id: "idAccount", numeric: false, disablePadding: false, label: "idAccount" },
    { id: "role", numeric: false, disablePadding: false, label: "Tipo" },
    { id: "name", numeric: false, disablePadding: false, label: "Nome" },
    { id: "info", numeric: false, disablePadding: false, label: "Father/Sons" },
    { id: "email", numeric: false, disablePadding: false, label: "E-mail" },
    { id: "cpf", numeric: false, disablePadding: false, label: "CPF" },
    { id: "hasDocumentError", numeric: false, disablePadding: false, label: "hasDocumentError" },
    { id: "missingSPDValidation", numeric: false, disablePadding: false, label: "missingSPDValidation" },
    { id: "phone", numeric: false, disablePadding: false, label: "Telefone" },
    { id: "createdAt", numeric: false, disablePadding: false, label: "Data de criação" },
  ];

  // const filter = useSelector(state => state.users.users.filterCount);
  const { cache, users, loading, stats, yours } = useSelector(state => state.users);
  console.log(yours.Saldoyours)
  const [page, setPage] = useState(cache.page);
  const [alreadySerched, setAlreadySerched] = useState(false);
  const [limit, setLimit] = useState(cache.limit);
  const [searchText, setSearchText] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [searchType, setSearchType] = useState('')

  const fetchUsers = useCallback(
    () => dispatch(actions.fetchUsers(cache.page, cache.limit, cache.search, cache.order, cache.orderBy)),
    [page, limit, searchText, order, orderBy]
  )

  const getStats = useCallback(() => dispatch(actions.fetchStats(), [stats]))
  const getYoursData = useCallback(() => dispatch(actions.fetchYoursInfo(), [dispatch, yours]))

  useEffect(() => {
    setAlreadySerched(true);
    getStats();
    getYoursData();
    switch (searchType) {
      case 'FWS':
        dispatch(actions.fetchFatherWithoutSon(cache.page, cache.limit, cache.search, cache.order, cache.orderBy));
        break;
      case 'SWF':
        dispatch(actions.fetchSonWithoutFather(cache.page, cache.limit, cache.search, cache.order, cache.orderBy));
        break;
      case 'SC':
        dispatch(actions.fetchSonWithCard(cache.page, cache.limit, cache.search, cache.order, cache.orderBy));
        break;
      case 'SWC':
        dispatch(actions.fetchSonWithoutCard(cache.page, cache.limit, cache.search, cache.order, cache.orderBy));
        break;
      default:
        fetchUsers();
    }
  }, [page, limit, order, orderBy]);

  useEffect(() => {
    if (searchText !== "" || alreadySerched) {
      const timer = setTimeout(() => {
        if (searchText === textInput.current.value) {
          fetchUsers(page, limit, searchText, order, orderBy);
        }
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchText, textInput]);

  const handleChangePage = (e, page) => {
    setPage(page + 1);
    dispatch(actions.fetchCacheTable(page + 1, limit, order, searchText, orderBy))
  };

  const handleChangeRowsPerPage = e => {
    setLimit(e.target.value);
    setPage(1);
    const num = parseInt(e.target.value)
    dispatch(actions.fetchCacheTable(page, num, order, searchText, orderBy))
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
    dispatch(actions.fetchCacheTable(page, limit, isDesc ? "asc" : "desc", '', property))
  };

  const selectRow = (event, id) => {
    props.history.push(`form-user/${id}`)
  }

  const selectExtract = () => {
    props.history.push(`extract/`)
  }

  const handleFilter = (param) => {
    switch (param) {
      case 'all':
        setSearchType('');
        dispatch(actions.fetchUsers(1, cache.limit, cache.search, cache.order, cache.orderBy));
        break;
      case 'FWS':
        setSearchType(param);
        dispatch(actions.fetchFatherWithoutSon(1, cache.limit, cache.search, cache.order, cache.orderBy));
        break;
      case 'SWF':
        setSearchType(param);
        dispatch(actions.fetchCacheTable(1, limit, order, '', orderBy))
        dispatch(actions.fetchSonWithoutFather(1, cache.limit, cache.search, cache.order, cache.orderBy));
        break;
      case 'SC':
        setSearchType(param);
        dispatch(actions.fetchSonWithCard(1, cache.limit, cache.search, cache.order, cache.orderBy));
        break;
      case 'SWC':
        setSearchType(param);
        dispatch(actions.fetchSonWithoutCard(1, cache.limit, cache.search, cache.order, cache.orderBy));
        break;
      default:
        setSearchType('')
        dispatch(actions.fetchUsers(1, cache.limit, cache.search, cache.order, cache.orderBy));
    }
  };

  return (
    <>
      <Paper>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              text="Cartões Desbloqueados"
              values={stats.CardsNormal}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              text="Cartões Bloqueados"
              values={stats.CardsBlocked}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              text="Cartões Cancelados"
              values={stats.CardsCancelled}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              text="Todos Usuários"
              values={stats.filterCount}
              click={() => handleFilter('all')}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}
          >
            <Budget
              text="Filhos sem pai"
              values={stats.SonWithoutFather}
              click={() => handleFilter('SWF')}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}
          >
            <Budget
              text="Filhos sem cartão"
              values={stats.SonWithoutCard}
              click={() => handleFilter('SWC')}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              text="Filhos com cartão"
              values={stats.SonWithCard}
              click={() => handleFilter('SC')}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              type="money"
              text="Saldo Yours"
              values={numberToCurrency(yours.Saldoyours)}
              click={() => selectExtract()}
            />
          </Grid>
        </Grid>

        <TableTitle
          title='Usuários'
          placeholder='Pesquisar'
          inputRef={textInput}
          value={cache.search}
          onChange={event => {
            setPage(1);
            dispatch(actions.fetchCacheTable(page, limit, order, event.target.value, orderBy))
            setSearchText(event.target.value);
          }}
        />
        <Table style={styles.table}>
          <tbody>
            <tr>
              <td>
                {loading &&
                  <div style={styles.spinner}>
                    <CircularProgress style={{ color: Colors.secondary }} />
                  </div>}
                {users.rows.length === 0 &&
                  <div style={styles.noResults}>
                    {!loading && <Typography style={styles.spinner}>Nenhum resultado encontrado</Typography>}
                  </div>}
              </td>
            </tr>
          </tbody>
          <EnhancedTableHead
            style={{ opacity: loading ? '0.3' : '1' }}
            headRows={headRows}
            order={cache.order}
            orderBy={cache.orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody style={{ opacity: loading ? '0.3' : '1' }}>
            {users.rows.map(user => {
              return (
                <TableRow
                  style={styles.tableRow}
                  key={user._id}
                  hover
                  onClick={event => selectRow(event, user._id)}
                >
                  <TableCell style={styles.cell}>{user.idAccount}</TableCell>
                  <TableCell style={styles.cell}>{user.role}</TableCell>
                  <TableCell style={styles.cell}>{capitalize(user.name)}</TableCell>
                  <TableCell style={styles.cell}>
                    {user.role === 'FATHER' ?
                      <div>
                        {user.sons.length === 0 ?
                          <div>(nenhum filho)</div> :
                          <div>
                            <div>FILHOS:</div>
                            {user.sons.map(son => <div key={son._id}>- {son.name}</div>)}
                          </div>
                        }
                      </div> :
                      <div>{user.father ? `${user.father.name} (FATHER)` : 'Sem pai vinculado'}</div>
                    }
                  </TableCell>
                  <TableCell style={styles.cell}>{user.email}</TableCell>
                  <TableCell style={styles.cell}>{user.cpf}</TableCell>
                  <TableCell style={styles.cell}>{user.hasDocumentError ? 'True' : 'False'}</TableCell>
                  <TableCell style={styles.cell}>{user.missingSPDValidation ? 'True' : 'False'}</TableCell>
                  <TableCell style={styles.cell}>{(!user.phone || Object.keys(user.phone).length === 0) ? '-' : `(${user.phone.areaCode})${user.phone.number}`}</TableCell>
                  <TableCell style={styles.cell}>{user.createdAt ? format(parseISO(user.createdAt), "dd/MM/yyyy", { locale: pt }) : '-'}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter style={{ opacity: loading ? '0.3' : '1' }}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={users.count}
                rowsPerPage={parseInt(limit)}
                page={page - 1}
                SelectProps={{
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </>
  );

}

export default TableUsers;

const styles = {
  container: {
    padding: 0
  },
  table: {
    position: 'relative',
    marginBottom: '4rem',
  },
  tableRow: {
    cursor: 'pointer'
  },
  spinner: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    top: '-5px',
    opacity: '1',
    zIndex: 2,
  },
  noResults: {
    height: '100px',
  },
  deleteTextConfirm: {
    marginLeft: '-30px',
    fontWeight: 'bold',
  },
  editWidth: {
    width: '90%'
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatar: {
    width: '2.75rem',
    height: '2.75rem',
  },
  avatarSmallText: {
    fontSize: '0.775rem',
    width: '2.75rem',
    height: '2.75rem',
  },
  chip: {
    margin: 2.5,
    cursor: 'pointer',
  },
  thumbUp: {
    marginRight: 15,
    color: "#349c34"
  },
  thumbDown: {
    marginRight: 15,
    color: "#d04545"
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
  },
  cell: {
    fontSize: '0.775rem',
    padding: '0.75rem',
  },
  cellChip: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  label: {
    marginLeft: '0.5rem'
  },
  Grid: {
    padding: 20,
  },
  Cards: {
  },
  root: {
    flexGrow: 1,
    padding: 20,
  },
  Paper: {
    textAlign: 'center',
    height: 70,
    padding: 5,
  },
  Title: {
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  SubTitle: {
    fontSize: '.9rem',
    color: "#666666"
  },
  avatar: {
    backgroundColor: 'red',
    height: 56,
    width: 56
  }
}
