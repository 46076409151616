import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import {
  Typography, Avatar, AppBar, Toolbar,
} from '@material-ui/core';

import PersonIcon from '@material-ui/icons/Person';
import Colors from '../../constants/Colors';

import logo from '../../assets/logo-Yours-pink.png'

export const NavLinkButtonStyled = styled(NavLink)`
  color: #333;
  text-decoration: none;
`
export const NavLinkStyled = styled(NavLink)`
  color: #333;
  /* text-decoration: none; */
`
const TypographyStyled = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: bold;
`;
const AvatarStyled = styled(Avatar)`
  margin: 10px !important;
  padding: 35px !important;
  background-color: ${Colors.primary} !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
`;

export const LogoText = () => (
  <img src={logo} style={styles.logo} />
);

const styles = {
  appbar: {
    backgroundColor: Colors.primary,
  },
  logo: {
    width: '12rem',
  },
}

export const Appbar = (props) => (
  <AppBar position="fixed" style={styles.appbar}>
    <Toolbar />
  </AppBar>
)