import * as actionTypes from './actionTypes';
import API from '../../services/api';
import API_LOGIN from '../../services/apiLogin';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr'

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  }
}

export const authSuccess = (token, user) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token,
    user,
  }
}

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error
  }
}

export const logout = () => {
	localStorage.removeItem('token');
	
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

export const auth = (cpf, password) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(authStart());

			const body = {};
      const headers = {
        "Authorization": "Basic " + btoa(`${cpf}:${password}`)
      }

      API_LOGIN.post('/auth', body, { headers: headers })
        .then(async res => {
          dispatch(authSuccess(res.data.user, res.data.token))
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('role', res.data.user.role);
          resolve(res.data);
        })
        .catch(err => {
          console.log(err);
          dispatch(authFail(err.response ? err.response : err))
          reject(err);
				})
			})
  }
}

