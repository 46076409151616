import React from 'react';
import { Route, Switch } from "react-router-dom";

import TableUsers from '../containers/Admin/Users/Tables/TableUsers';
import Push from '../containers/Admin/Push/index';
import Extract from '../containers/Admin/Extract/index';
import Forms from '../containers/Admin/Users/Forms';

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={TableUsers} />
      <Route path="/push" exact component={Push} />
      <Route path="/form-user/:id" exact component={Forms} />
      <Route path="/extract/" exact component={Extract} />
    </Switch>
  );
}
export default Routes;
