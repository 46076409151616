import React from 'react';
import { Paper, Typography, Grid, CardActionArea  } from '@material-ui/core';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';

import Colors from '../../../../../../constants/Colors';

const StyledPaper = styled(Paper)`
  padding: 8px;
`;

const CenteredText = styled(Typography)`
  width: 100%;
  text-align: center;
  margin: 8px 0 !important;
`;

const CustomCard = styled(CardActionArea)`
  border-radius: 4px !important;
  box-shadow: ${props => props.active ? `0 0 0 3px ${Colors.primary};` : undefined};
`;

const Card = ({
  id = '-',
  status = '-',
  statusColor = '#000',
  number = '-',
  issueDate = Date.now(),
  validThru = Date.now(),
  active,
  toggleActive,
}) => (
  <CustomCard onClick={toggleActive} active={active ? 1 : 0}>
    <StyledPaper elevation={3}>
      <Grid container spacing={1} justify="space-between">
        <Grid item>
          <Typography variant="caption">Status: {status}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">ID: {id}</Typography>
        </Grid>
      </Grid>
      <CenteredText variant="h6" style={{ color: statusColor }}>{number.match(/.{1,4}/g).map(s => s + ' ')}</CenteredText>
      <Grid container spacing={2} justify="space-between">
        <Grid item>
          <Typography variant="caption">Emissão: {format(parseISO(issueDate), "dd/MM/yyyy", { locale: pt })}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">Validade: {format(parseISO(validThru), "dd/MM/yyyy", { locale: pt })}</Typography>
        </Grid>
      </Grid>
    </StyledPaper>
   </CustomCard >
);

export default Card;
