import * as actionTypes from './actionTypes';
import API from '../../services/api';
import axios from 'axios';
import { QueryBuilder } from '@material-ui/icons';

let CancelToken = axios.CancelToken;
let fetchUsers_cancel;
let fetchUsersById_cancel;
let fetchSonsByFatherId_cancel;
let fetchSonAccount_cancel;
let fetchTrack_cancel;
let fetchInvite_cancel;

export const requestUsersStart = () => {
	return {
		type: actionTypes.REQUEST_USERS_START
	};
};
export const requestUsersByIdStart = () => {
	return {
		type: actionTypes.REQUEST_USERS_BY_ID_START
	};
};
export const requestSonsByFatherIdStart = () => {
	return {
		type: actionTypes.REQUEST_SONS_BY_FATHER_ID_START
	};
};
export const requestSonCardsStart = () => {
	return {
		type: actionTypes.REQUEST_SON_CARDS_START
	};
};

export const requestSonVirtualCardsStart = () => {
	return {
		type: actionTypes.REQUEST_SON_VIRTUAL_CARDS_START
	};
};

export const requestSonCardsLoadingStart = () => {
	return {
		type: actionTypes.REQUEST_SON_CARDS_LOADING_START
	};
};
export const requestStatementStart = () => {
	return {
		type: actionTypes.REQUEST_STATEMENT_START
	};
};
export const requestSonAccountStart = () => {
	return {
		type: actionTypes.REQUEST_SON_ACCOUNT_START
	};
};
export const requestTrackStart = () => {
	return {
		type: actionTypes.REQUEST_TRACK_START
	};
};
export const requestStatementInfiniteScrollStart = () => {
	return {
		type: actionTypes.REQUEST_STATEMENT_INFINITE_SCROLL_START
	};
};
export const requestInviteStart = () => {
	return {
		type: actionTypes.REQUEST_INVITE_START
	};
};
export const requestStatsStart = () => {
	return {
		type: actionTypes.REQUEST_STATS_START
	};
};
// export const fetchRequestCardDuplicateSuccess = () => {
// 	return {
// 		type: actionTypes.REQUEST_DUPLICATE_SUCCESS
// 	};
// };

// export const fetchRequestCardDuplicateFail = () => {
// 	return {
// 		type: actionTypes.REQUEST_DUPLICATE_FAIL
// 	};
// };

export const requestUsersFail = (error) => {
	return {
		type: actionTypes.REQUEST_USERS_FAIL,
		error: error
	};
};
export const requestUsersByIdFail = (error) => {
	return {
		type: actionTypes.REQUEST_USERS_BY_ID_FAIL,
		error: error
	};
};
export const requestSonsByFatherIdFail = (error) => {
	return {
		type: actionTypes.REQUEST_SONS_BY_FATHER_ID_FAIL,
		error: error
	};
};
export const requestSonCardsFail = (error) => {
	return {
		type: actionTypes.REQUEST_SON_CARDS_FAIL,
		error: error
	};
};
export const requestSonVirtualCardsFail = (error) => {
	return {
		type: actionTypes.REQUEST_SON_VIRTUAL_CARDS_FAIL,
		error: error
	};
};
export const requestStatementFail = (error) => {
	return {
		type: actionTypes.REQUEST_STATEMENT_FAIL,
		error: error
	};
};
export const requestSonAccountFail = (error) => {
	return {
		type: actionTypes.REQUEST_SON_ACCOUNT_FAIL,
		error: error
	};
};
export const requestTrackFail = (error) => {
	return {
		type: actionTypes.REQUEST_TRACK_FAIL,
		error: error
	};
};
export const requestInviteFail = (error) => {
	return {
		type: actionTypes.REQUEST_INVITE_FAIL,
		error: error
	};
};
export const requestStatsFail = (error) => {
	return {
		type: actionTypes.REQUEST_STATS_FAIL,
		error: error
	}
}

export const fetchUsersSuccess = (users) => {
	return {
		type: actionTypes.FETCH_USERS_SUCCESS,
		users: users
	};
};
export const fetchUsersByIdSuccess = (user) => {
	return {
		type: actionTypes.FETCH_USERS_BY_ID_SUCCESS,
		user,
	};
};
export const fetchSonsByFatherIdSuccess = (sons) => {
	return {
		type: actionTypes.FETCH_SONS_BY_FATHER_ID_SUCCESS,
		sons
	}
}
export const fetchSonCardsSuccess = (cards) => {
	return {
		type: actionTypes.FETCH_SON_CARDS_SUCCESS,
		cards
	}
}

export const fetchSonVirtualCardsSuccess = (cards) => {
	return {
		type: actionTypes.FETCH_SON_VIRTUAL_CARDS_SUCCESS,
		cards
	}
}

export const fetchStatementSuccess = (statement) => {
	return {
		type: actionTypes.FETCH_STATEMENT_SUCCESS,
		statement,
	};
};
export const fetchStatementInfiniteScrollSuccess = (statement) => {
	return {
		type: actionTypes.FETCH_STATEMENT_INFINITE_SCROLL_SUCCESS,
		statement,
	};
};
export const fetchInviteSuccess = (invites) => {
	return {
		type: actionTypes.FETCH_INVITE_SUCCESS,
		invites,
	};
};
export const fetchSonAccountSuccess = (accounts) => {
	return {
		type: actionTypes.FETCH_SON_ACCOUNT_SUCCESS,
		accounts,
	};
};
export const fetchTrackSuccess = (track) => {
	return {
		type: actionTypes.FETCH_TRACK_SUCCESS,
		track,
	};
};
export const fetchStatsSuccess = (stats) => {
	return {
		type: actionTypes.FETCH_STATS_SUCCESS,
		stats,
	};
}


export const requestDeleteStart = () => {
	return {
		type: actionTypes.REQUEST_DELETE_START
	}
}

export const fetchDeleteSuccess = (data) => {
	return {
		type: actionTypes.FETCH_DELETE_SUCCESS,
		data: data
	}
}

export const requestDeleteFail = (error) => {
	return {
		type: actionTypes.FETCH_DELETE_FAIL,
		error
	}
}

export const fetchUsers = (page, limit, search, order, orderBy) => {
	fetchUsers_cancel && fetchUsers_cancel()
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestUsersStart());
			const queryParams = `?page=${!page || page < 1 ? 1 : page}&limit=${!limit || limit < 5 ? 5 : limit}&q=${search ? search : ''}&sort=${order === 'desc' ? '-' : ''}${orderBy}`;
			API.get('/user/admin/show' + queryParams, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsers_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchUsersSuccess(res.data));
					resolve(res.data);
				})
				.catch(err => {
					dispatch(requestUsersFail(err));
					reject(err);
				});
		});
	}
};

export const fetchFatherWithoutSon = (page, limit, search, order, orderBy) => {
	fetchUsers_cancel && fetchUsers_cancel()
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestUsersStart());
			const queryParams = `?page=${!page || page < 1 ? 1 : page}&limit=${!limit || limit < 5 ? 5 : limit}&q=${search ? search : ''}&sort=${order === 'desc' ? '-' : ''}${orderBy}`;
			API.get('/user/admin/showfatherwithoutson' + queryParams, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsers_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchUsersSuccess(res.data));
					resolve(res.data);
				})
				.catch(err => {
					dispatch(requestUsersFail(err));
					reject(err);
				});
		});
	}
};

export const fetchSonWithoutFather = (page, limit, search, order, orderBy) => {
	fetchUsers_cancel && fetchUsers_cancel()
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestUsersStart());
			const queryParams = `?page=${!page || page < 1 ? 1 : page}&limit=${!limit || limit < 5 ? 5 : limit}&q=${search ? search : ''}&sort=${order === 'desc' ? '-' : ''}${orderBy}`;
			API.get('/user/admin/showsonwithoutfather' + queryParams, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsers_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchUsersSuccess(res.data));
					resolve(res.data);
				})
				.catch(err => {
					dispatch(requestUsersFail(err));
					reject(err);
				});
		});
	}
};

export const fetchSonWithoutCard = (page, limit, search, order, orderBy) => {
	fetchUsers_cancel && fetchUsers_cancel()
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestUsersStart());
			const queryParams = `?page=${!page || page < 1 ? 1 : page}&limit=${!limit || limit < 5 ? 5 : limit}&q=${search ? search : ''}&sort=${order === 'desc' ? '-' : ''}${orderBy}`;
			API.get('/user/admin/showsonwithoutcard' + queryParams, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsers_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchUsersSuccess(res.data));
					resolve(res.data);
				})
				.catch(err => {
					dispatch(requestUsersFail(err));
					reject(err);
				});
		});
	}
};

export const fetchSonWithCard = (page, limit, search, order, orderBy) => {
	fetchUsers_cancel && fetchUsers_cancel()
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestUsersStart());
			const queryParams = `?page=${!page || page < 1 ? 1 : page}&limit=${!limit || limit < 5 ? 5 : limit}&q=${search ? search : ''}&sort=${order === 'desc' ? '-' : ''}${orderBy}`;
			API.get('/user/admin/showsonwithcard' + queryParams, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsers_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchUsersSuccess(res.data));
					resolve(res.data);
				})
				.catch(err => {
					dispatch(requestUsersFail(err));
					reject(err);
				});
		});
	}
};

export const fetchStats = () => {
	fetchUsers_cancel && fetchUsers_cancel();
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestStatsStart());
			API.get(`/user/admin/stats`
			)
				.then(res => {
					dispatch(fetchStatsSuccess(res.data));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(requestStatsFail(err));
					reject(err);
				});
		});
	}
};

export const fetchUsersById = (id) => {
	fetchUsersById_cancel && fetchUsersById_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestUsersByIdStart());
			API.get(`/user/admin/showone/${id}`, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsersById_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchUsersByIdSuccess(res.data));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(requestUsersByIdFail(err));
					reject(err);
				});
		});
	}
};

export const fetchSonsByFatherId = (id) => {
	fetchSonsByFatherId_cancel && fetchSonsByFatherId_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestSonsByFatherIdStart());
			API.get(`/card/admin/sons/${id}`, {
				cancelToken: new CancelToken(function executor(c) {
					fetchSonsByFatherId_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchSonsByFatherIdSuccess(res.data));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(requestSonsByFatherIdFail(err));
					reject(err);
				});
		});
	}
}

export const fetchSonById = (id) => {
	fetchUsersById_cancel && fetchUsersById_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestSonsByFatherIdStart());
			API.get(`/card/admin/${id}`, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsersById_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchSonsByFatherIdSuccess(res.data));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(requestSonsByFatherIdFail(err));
				});
		});
	}
};

export const fetchSonCardsById = (id) => {
	fetchUsersById_cancel && fetchUsersById_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestSonCardsStart());
			API.get(`/card/admin/user-cards/${id}`, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsersById_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchSonCardsSuccess(res.data));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(requestSonCardsFail(err));
				});
		});
	}
};

export const fetchSonVirtualCardsById = (id) => {
	const query = id ? `?id=${id}` : ''
	fetchUsersById_cancel && fetchUsersById_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestSonVirtualCardsStart());
			API.get(`/card/virtualcard${query}`)
				.then(res => {
					dispatch(fetchSonVirtualCardsSuccess(res.data));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(requestSonVirtualCardsFail(err));
				});
		});
	}
};

export const fetchRequestCardDuplicate = (id, charging, idCard) => {
	const body = {
		"idCard": idCard,
	}
	fetchUsersById_cancel && fetchUsersById_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestSonCardsLoadingStart());
			API.post(`/card/admin/${id}/duplicate/${charging}`, body, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsersById_cancel = c;
				})
			})
				.then(res => {
					resolve(res.data)
				})
				.catch(err => {
					reject(err)
				});
		});
	}
};

export const fetchSonsAccount = (id) => {
	fetchSonAccount_cancel && fetchSonAccount_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestSonAccountStart());
			API.get(`/account/admin/sons/${id}`, {
				cancelToken: new CancelToken(function executor(c) {
					fetchSonAccount_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchSonAccountSuccess(res.data));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(requestSonAccountFail(err));
					reject(err);
				});
		});
	}
}

export const fetchUnicSonAccount = (id) => {
	fetchSonAccount_cancel && fetchSonAccount_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestSonAccountStart());
			API.get(`/account/admin/${id}`, {
				cancelToken: new CancelToken(function executor(c) {
					fetchSonAccount_cancel = c;
				})
			})
				.then(res => {
					const newRes = { count: 1, rows: [res.data] }
					dispatch(fetchSonAccountSuccess(newRes));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(requestSonAccountFail(err));
					reject(err);
				});
		});
	}
}

export const fetchStatement = (idAccount, page, limit, infiniteScroll = false) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			if (!infiniteScroll) {
				dispatch(requestStatementStart());
			} else {
				dispatch(requestStatementInfiniteScrollStart());
			}
			const queryParams = `?page=${!page || page < 1 ? 1 : page}&limit=${!limit || limit < 5 ? 10 : limit}`;
			API.get(`/account/admin/${idAccount}/statement` + queryParams, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsers_cancel = c;
				})
			})
				.then(res => {
					if (!infiniteScroll) {
						dispatch(fetchStatementSuccess(res.data));
					} else {
						dispatch(fetchStatementInfiniteScrollSuccess(res.data));
					}
					resolve(res.data);
				})
				.catch(err => {
					dispatch(requestStatementFail(err));
					reject(err);
				})
		})
	};
};

export const fetchStatementYours = (page, limit, infiniteScroll = false) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			if (!infiniteScroll) {
				dispatch(requestStatementStart());
			} else {
				dispatch(requestStatementInfiniteScrollStart());
			}
			const queryParams = `?page=${!page || page < 1 ? 1 : page}&limit=${!limit || limit < 5 ? 10 : limit}`;
			API.get(`/account/admin/statementYours` + queryParams, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsers_cancel = c;
				})
			})
				.then(res => {
					if (!infiniteScroll) {
						dispatch(fetchStatementSuccess(res.data));
					} else {
						dispatch(fetchStatementInfiniteScrollSuccess(res.data));
					}
					resolve(res.data);
				})
				.catch(err => {
					dispatch(requestStatementFail(err));
					reject(err);
				})
		})
	};
};

export const fetchTrackCard = (id) => {
	fetchTrack_cancel && fetchTrack_cancel()
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestTrackStart());
			API.get(`/card/admin/track/${id}`, {
				cancelToken: new CancelToken(function executor(c) {
					fetchTrack_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchTrackSuccess(res.data));
					resolve(res.data);
				})
				.catch(err => {
					dispatch(requestTrackFail(err));
					reject(err);
				})
		})
	}
}

export const fetchInviteCard = (id) => {
	fetchInvite_cancel && fetchInvite_cancel()
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestInviteStart());
			API.post(`/invite/admin/${id}`, {
				cancelToken: new CancelToken(function executor(c) {
					fetchInvite_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchInviteSuccess(res.data));
					resolve(res.data);
				})
				.catch(err => {
					dispatch(requestInviteFail(err));
					reject(err);
				})
		})
	}
}

// **************************************************************************************************** //
export const requestCacheStart = () => {
	return {
		type: actionTypes.REQUEST_CACHE_START
	}
}

export const fetchCacheSuccess = (cache) => {
	return {
		type: actionTypes.FETCH_CACHE_SUCCESS,
		cache: cache
	}
}

export const requestCacheFail = (error) => {
	return {
		type: actionTypes.FETCH_CACHE_FAIL,
		error
	}
}

export const fetchCacheTable = (page, limit, order, search, orderBy) => {
	return dispatch => {
		const newRes = { page: page, limit: limit, order: order, search: search, orderBy: orderBy }
		dispatch(fetchCacheSuccess(newRes));
	}
}


export const fetchYoursInfoSuccess = (data) => {
	return {
		type: actionTypes.FETCH_YOURSINFO_SUCCESS,
		data,
	};
}

export const fetchYoursInfoFail = (error) => {
	return {
		type: actionTypes.REQUEST_YOURSINFO_FAIL,
		error: error
	}
}

export const fetchYoursInfo = () => {
	fetchUsers_cancel && fetchUsers_cancel();
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestStatsStart());
			API.get(`/account/admin/yours/6`
			)
				.then(res => {
					dispatch(fetchYoursInfoSuccess(res.data.saldoDisponivelGlobal));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(fetchYoursInfoFail(err));
					reject(err);
				});
		});
	}
};

export const fetchRequestBlockCard = (id, card) => {
	const body = {
		"status": 2,
		"observation": "Observacao"
	}
	fetchUsersById_cancel && fetchUsersById_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestSonCardsLoadingStart());
			API.post(`/card/admin/${id}/block?card=${card}`, body, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsersById_cancel = c;
				})
			})
				.then(res => {
					resolve(res.data)
				})
				.catch(err => {
					reject(err)
				});
		});
	}
};

export const fetchRequestUnblockCard = (id, card, unblockCode) => {
	fetchUsersById_cancel && fetchUsersById_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestSonCardsLoadingStart());
			API.post(`/card/admin/${id}/unblock?card=${card}`, { unblockCode }, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsersById_cancel = c;
				})
			})
				.then(res => {
					resolve(res.data)
				})
				.catch(err => {
					reject(err)
				});
		});
	}
};

export const fetchRequestCancelCard = (id, card) => {
	const body = {
		"status": 4,
		"observation": "Observacao"
	}
	fetchUsersById_cancel && fetchUsersById_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestSonCardsLoadingStart());
			API.post(`/card/admin/${id}/cancel?card=${card}`, body, {
				cancelToken: new CancelToken(function executor(c) {
					fetchUsersById_cancel = c;
				})
			})
				.then(res => {
					resolve(res.data)
				})
				.catch(err => {
					reject(err)
				});
		});
	}
};

export const fetchCancelAccount = (id, idAccount) => {
	const body = {
		"status": 2,
	}
	fetchSonAccount_cancel && fetchSonAccount_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestCancelStart());
			API.post(`/account/admin/${id}/cancel?account=${idAccount}`, body, {
				cancelToken: new CancelToken(function executor(c) {
					fetchSonAccount_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchCancelAccountSuccess(res.data));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(requestCancelAccountFail(err));
					reject(err);
				});
		});
	}
}

export const requestCancelStart = () => {
	return {
		type: actionTypes.REQUEST_CANCELACC_START
	}
}

export const fetchCancelAccountSuccess = (data) => {
	return {
		type: actionTypes.FETCH_CANCELACC_SUCCESS,
		data: data
	}
}

export const requestCancelAccountFail = (error) => {
	return {
		type: actionTypes.FETCH_CANCELACC_FAIL,
		error
	}
}

export const fetchDeleteAccount = (id) => {
	fetchSonAccount_cancel && fetchSonAccount_cancel()
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(requestDeleteStart());
			API.delete(`/user/admin/${id}`, {
				cancelToken: new CancelToken(function executor(c) {
					fetchSonAccount_cancel = c;
				})
			})
				.then(res => {
					dispatch(fetchDeleteSuccess(res.data));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(requestDeleteFail(err));
					reject(err);
				});
		})
	}
}

export const fetchImagesUser = (id) => {
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(fetchImagesUserStart());
			API.get(`/account/admin/getImagesUser/${id}`)
				.then(res => {
					dispatch(fetchImagesUserSuccess(res.data));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(requestImagesUserFail(err));
					reject(err);
				});
		})
	}
}

export const fetchImagesUserStart = () => {
	return {
		type: actionTypes.FETCH_IMAGES_USER_START
	}
}

export const fetchImagesUserSuccess = (data) => {
	return {
		type: actionTypes.FETCH_IMAGES_USER_SUCCESS,
		data: data
	}
}

export const requestImagesUserFail = (error) => {
	return {
		type: actionTypes.FETCH_IMAGES_USER_FAIL,
		error
	}
}

export const updateUser = (id, email, phone, cep, neighborhood, street, number, complement, birthdate) => {
	const body = {
		"email": email,
		"phone": {
			"areaCode": phone.slice(0, 2),
			"number": phone.slice(2, 11)
		},
		"birthDate": birthdate,
		"address": {
			"zipCode": cep,
			"neighborhood": neighborhood,
			"street": street, 
			"number": number,
			"complement": complement
		},
	}
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(updateUserStart());
			API.put(`/user/admin/update-user/${id}`, body)
				.then(res => {
					dispatch(updateUserSuccess(res.data));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(updateUserFail(err));
					reject(err)
				});
		});
	}
}

export const changeSpdStatus = (id) => {
	const body = {
		'hasDocumentError': false,
		'missingSPDValidation': false,
		'field': 'spd',
	}
	return async dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(changeSpdStatusStart());
			API.put(`user/admin/update/${id}`, body)
				.then(res => {
					dispatch(changeSpdStatusSuccess(res.data));
					resolve(res.data)
				})
				.catch(err => {
					dispatch(changeSpdStatusFail(err));
					reject(err)
				});
		});
	}
}

export const updateUserStart = () => {
	return {
		type: actionTypes.UPDATE_USER_START
	}
}

export const updateUserSuccess = (data) => {
	return {
		type: actionTypes.UPDATE_USER_SUCCESS,
		data: data
	}
}

export const updateUserFail = (error) => {
	return {
		type: actionTypes.UPDATE_USER_FAIL,
		error
	}
}

export const changeSpdStatusStart = () => {
	return {
		type: actionTypes.CHANGE_SPD_STATUS_START
	}
}

export const changeSpdStatusSuccess = (data) => {
	return {
		type: actionTypes.CHANGE_SPD_STATUS_SUCCESS,
		data: data
	}
}

export const changeSpdStatusFail = (error) => {
	return {
		type: actionTypes.CHANGE_SPD_STATUS_FAIL,
		error
	}
}