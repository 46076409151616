import React from 'react';
import Routes from './Routes';
import "./styles/global";

const App = () => {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
