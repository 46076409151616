import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Typography,
  Paper,
  FormControl,
  TextField,
  Button,
  Grid
} from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot
} from '@material-ui/lab';
import { Payment } from '@material-ui/icons';
import moment from 'moment';

import { cpfMask } from '../../../../utils/cpfValidator';
import { phoneMask } from '../../../../utils/phoneValidator';

import * as actions from '../../../../store/actions';
import ConfirmDialog from '../../../../UI/Confirm/ConfirmDialog';
import SpinnerOverlay from '../../../../UI/SpinnerOverlay/SpinnerOverlay';

import { FormStyled, DivFormTitle, style } from './styles';

const FormPix = () => {
  const [pixLimit, setPixLimit] = useState(10);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.users);
  const { pix, keys, loading, pixTotalLimit, pixCount } = useSelector(state => state.pix);

  useEffect(() => {
    dispatch(actions.fetchPixLimit(user._id));
    dispatch(actions.fetchPixKeys(user._id));
  }, [dispatch, user._id])

  const fetchPix = useCallback((id, page, limit, infiniteScroll) => {
    dispatch(actions.fetchPix(id, page, limit, infiniteScroll));
  }, [dispatch]);

  useEffect(() => {
    if(!user.account) return;
    fetchPix(user._id, page, limit, page > 1);
  }, [page, limit, user, fetchPix]);

  const nextPage = () => setPage(p => p + 1);

  const toggleDialog = () => setShowDialog(show => !show);

  const handleSubmit = (e) => {
    e.preventDefault();
    toggleDialog();
  }

  const addPixLimit = () => {
    dispatch(actions.addPixLimit(user._id, pixLimit));
    toggleDialog();
  }

  const getKeyTypeString = (type) => {
    switch (type) {
      case 'evp':
        return 'Chave Aleatória';
      case 'nationalRegistration':
        return 'CPF/CNPJ';
      case 'phoneNumber':
        return 'Celular';
      case 'email':
        return 'E-mail';
      default:
        return '';
    }
  }

  const getMaskedKey = (type, key) => {
    switch (type) {
      case 'nationalRegistration':
        return cpfMask(key);
      case 'phoneNumber':
        return `${key.substring(0, 3)} ${phoneMask(key.substring(3, key.length))}`;
      default:
        return key;
    }
  };

  return (
    <Container maxWidth='md' style={{ position: 'relative' }}>
      <ConfirmDialog
        show={showDialog}
        proceed={addPixLimit}
        dismiss={toggleDialog}
        cancel={toggleDialog}
        confirmation={`Tem certeza que deseja adicionar ${pixLimit} de limite para o usuário ${user.name}?`}
        options={{
          title: 'Adicionar limite PIX',
          titleColor: '#777'
        }}
      />
      <Paper>
        <FormStyled onSubmit={handleSubmit}>
          <DivFormTitle>
            <Typography style={{ fontSize: style.fontSize }} variant='h6'>PIX</Typography>
          </DivFormTitle>
          <Typography style={{ fontSize: '1rem' }} variant='h6'>PIX realizados: {pixCount} / {pixTotalLimit}</Typography>
          <FormControl fullWidth>
            <Grid
              container
              alignItems="flex-end"
              spacing={3}
            >
              <Grid item sm={2}>
                <TextField
                  fullWidth
                  label="PIX Adicional"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={event => setPixLimit(event.target.value)}
                  value={pixLimit}
                  margin="normal"
                  type="number"
                />
              </Grid>
              <Grid item sm={3}>
                <Button
                  disabled={pixCount === 0}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Adicionar Limite
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </FormStyled>
        {loading && <SpinnerOverlay />}
        {keys.length > 0 && (
          <FormStyled>
            <DivFormTitle>
              <Typography style={{ fontSize: style.fontSize }} variant='h6'>Chaves PIX</Typography>
            </DivFormTitle>
            <Grid container spacing={1}>
              {keys.map(key => (
                <Grid item key={key.key} xs={4}>
                  <Paper elevation={3} style={{ padding: '4px 8px' }}>
                    <Typography variant="body1">{getKeyTypeString(key.keyType)}</Typography>
                    <Typography variant="body2">{getMaskedKey(key.keyType, key.key)}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </FormStyled>
        )}
        <Timeline align="alternate">
          {pix.map((item, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                {`Transação em ${moment(item.transactionDate).format('DD/MM/YYYY')}`}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                <Payment />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} style={{ padding: '6px 16px' }}>
                  <Typography variant="h6" component="h1">
                    Para: {item.creditParty.name}
                  </Typography>
                  <Typography style={{ fontSize: 16, color: 'grey'}}>De: {item.debitParty.name}</Typography>
                  <Typography style={{ fontSize: 14, color: 'grey', fontWeight: 'bold'}} >
                    Valor: R${item.finalAmount}
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
        {pix.length >= limit && (
          <Grid
            justify="center"
            container
          >
            <Button style={{ margin: '5px 0', background: '#3B5998' }} onClick={nextPage} >
              <Typography style={{ color: '#fff' }}>Show More</Typography>
            </Button>
          </Grid>
        )}
      </Paper>
    </Container>
  );
};

export default FormPix;
