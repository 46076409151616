import React from "react";

import { CircularProgress } from "@material-ui/core";
import Colors from "../../constants/Colors";

const SpinnerOverlay = (props) => {
  const styles = {
    spinner: {
      position: props.relative ? 'relative' : 'absolute',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      opacity: "1",
      zIndex: 2
    }
  };

  return (
    <div style={{ ...styles.spinner, ...props.style }}>
      <CircularProgress style={{ color: Colors.primary }} />
    </div>
  );
};

export default SpinnerOverlay;
