import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';

const Confirm = ({ show, proceed, proceed1, dismiss, cancel, confirmation, options, cancelText, proceedText, proceedText1 }) => {
  return (
    <Dialog
      disableBackdropClick={options.disableBackdrop}
      disableEscapeKeyDown={options.disableKeyDown}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={show}
      onClose={dismiss}
    >
      <DialogTitle id="confirmation-dialog-title" style={{ color: options.titleColor }}>{options.title}</DialogTitle>
      <DialogContent dividers>
        <Typography variant='h6'>
          {confirmation}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => cancel('callback cancel')} color="primary">
          {cancelText || 'CANCELAR'}
        </Button>
        <Button onClick={() => proceed('callback ok')} color="primary">
          {proceedText || 'OK'}
        </Button>
        { proceedText1 && 
          <Button onClick={() => proceed1('callback ok')} color="primary">
            {proceedText1}
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

Confirm.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  confirmation: PropTypes.string,  // arguments of your confirm function
  options: PropTypes.object        // arguments of your confirm function
}

export default confirmable(Confirm);
