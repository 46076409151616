import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';

const initialState = {
  pix: [],
  keys: [],
  pixCount: 0,
  pixTotalLimit: 10,
  loadingKeys: false,
  loadingLimit: false,
  loading: false,
  loadingInfiniteScroll: false,
};

const requestPixStart = (state, action) => {
  return updateObject(state, { pix: [], loading: true });
};

const requestPixInfiniteScrollStart = (state, action) => {
  return updateObject(state, { loadingInfiniteScroll: true });
};

const requestPixLimitStart = (state, action) => {
  return updateObject(state, { pixCount: 0, pixTotalLimit: 10, loadingLimit: true });
};

const requestPixKeysStart = (state, action) => {
  return updateObject(state, { keys: [], loadingKeys: true });
};

const fetchPixSuccess = (state, action) => {
  return updateObject(state, {
    pix: action.pix,
    loading: false
  });
};

const fetchPixKeysSuccess = (state, action) => {
  return updateObject(state, {
    keys: action.keys,
    loadingKeys: false
  });
};

const fetchPixInfiniteScrollSuccess = (state, action) => {
  return updateObject(state, {
    pix: [...state.pix, ...action.pix],
    loadingInfiniteScroll: false
  });
};

const addPixLimitSuccess = (state, action) => {
  return updateObject(state, {
    pixCount: action.pixCount,
    pixTotalLimit: action.pixTotalLimit,
    loadingLimit: false
  });
};

const requestPixFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const requestPixKeysFail = (state, action) => {
  return updateObject(state, { loadingKeys: false });
};

const addPixLimitFail = (state, action) => {
  return updateObject(state, { pixCount: 0, pixTotalLimit: 10, loadingLimit: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_PIX_START: return requestPixStart(state, action);
    case actionTypes.REQUEST_PIX_KEYS_START: return requestPixKeysStart(state, action);
    case actionTypes.REQUEST_PIX_INFINITE_SCROLL_START: return requestPixInfiniteScrollStart(state, action);
    case actionTypes.REQUEST_PIX_LIMIT_START: return requestPixLimitStart(state, action);
    case actionTypes.FETCH_PIX_SUCCESS: return fetchPixSuccess(state, action);
    case actionTypes.FETCH_PIX_KEYS_SUCCESS: return fetchPixKeysSuccess(state, action);
    case actionTypes.ADD_PIX_LIMIT_SUCCESS: return addPixLimitSuccess(state, action);
    case actionTypes.FETCH_PIX_INFINITE_SCROLL_SUCCESS: return fetchPixInfiniteScrollSuccess(state, action);
    case actionTypes.REQUEST_PIX_FAIL: return requestPixFail(state, action);
    case actionTypes.REQUEST_PIX_KEYS_FAIL: return requestPixKeysFail(state, action);
    case actionTypes.ADD_PIX_LIMIT_FAIL: return addPixLimitFail(state, action);

    default: return state;
  }
};

export default reducer;
