import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  Box
} from '@material-ui/core';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { CreditCard, Person, AttachMoney } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 5,
  },
  avatar: {
    backgroundColor: "#8E03A7",
    height: 36,
    width: 36
  },
  differenceIcon: {
    color: "colors.red[900]"
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const Budget = ({ className, text, values, click, type }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <ButtonBase
        style={{width: '100%'}}
        onClick={click}
      >
      <CardContent
        style={{width: '100%'}}
      >
        <Grid
          container
          justify="space-between"
          spacing={3}
        >

          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="subtitle1"
            >
              {text}
            </Typography>
            <Typography
              style={{textAlign: 'left'}}
              color="textPrimary"
              variant="h6"
            >
              {values}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              {!type ? <Person /> : <AttachMoney />}
            </Avatar>
          </Grid>
        </Grid>
        {/* <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          <ArrowDownwardIcon className={classes.differenceIcon} />
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            12%
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Since last month
          </Typography>
        </Box> */}
      </CardContent>
      </ButtonBase>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string
};

export default Budget;
