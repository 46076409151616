import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import SpinnerOverlay from '../../../../../UI/SpinnerOverlay/SpinnerOverlay';
import CurrencyInput from './TextMask';

const FormDialog = ({
  sendTransfer, 
  dialogstatus, 
  cancelAcc, 
  dialogstatusCancel,
  deleteAcc,
  dialogstatusDelete,
  removeSpdStatus,
  dialogSpdStatus
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(dialogstatus);
  const [openDialog2, setOpenDialog2] = useState(dialogstatus);
  const [openDialog3, setOpenDialog3] = useState(dialogstatus);
  const [openDialog4, setOpenDialog4] = useState(dialogSpdStatus);
  const [textInput, setTextInput] = useState("");
  const { loading, loadingCancel, loadingDelete } = useSelector(state => state.account);
  const { loadingUpdateUser } = useSelector(state => state.users);

  useEffect(() => {
    setOpen(dialogstatus);
    setOpenDialog2(dialogstatusCancel);
    setOpenDialog3(dialogstatusDelete);
    setOpenDialog4(dialogSpdStatus);
  }, [dialogstatus, dialogstatusCancel, dialogstatusDelete, dialogSpdStatus])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpenDialog2(true);
  };
  const handleClickOpen3 = () => {
    setOpenDialog3(true);
  };
  const handleClickOpen4 = () => {
    setOpenDialog4(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpenDialog2(false);
  };
  const handleClose3 = () => {
    setOpenDialog3(false);
  };
  const handleClose4 = () => {
    setOpenDialog4(false);
  };

  return (
    <div>
      <ColorButton variant="contained" style={{background: '#2a9d8f', color: '#fff'}} className={classes.margin} onClick={handleClickOpen}>
        Adicionar Saldo
      </ColorButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {loading && <SpinnerOverlay />}
        <DialogTitle id="form-dialog-title" style={{background: '#2a9d8f', color: '#fff'}}>
          Adicionar Saldo
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Typography variant="title" color="inherit" noWrap>
            Deposite saldo na conta de pais que tenham a conta completa.
          </Typography>
          <Typography variant="subheading" color="inherit" noWrap>
            (Valores aceitos: até R$50)
          </Typography>
          </DialogContentText>
          <CurrencyInput placeholder="$0.00" type="text" onChange={event => {setTextInput(event.target.value)}}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => sendTransfer(textInput)} color="primary">
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    
      <ColorButton variant="contained" style={{background: '#023047', color: '#fff'}} className={classes.margin} onClick={handleClickOpen2}>
        Cancelar Conta
      </ColorButton>
      <Dialog open={openDialog2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        {loadingCancel && <SpinnerOverlay />}
        <DialogTitle id="form-dialog-title" style={{background: '#023047', color: '#fff'}}>Cancelar Conta</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Typography variant="title" color="inherit" noWrap>
            Deseja realizar o cancelamento dessa conta?
          </Typography>
          <Typography variant="title" style={{fontWeight: 'bold'}} noWrap>
            Está operação é permanente!
          </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Voltar
          </Button>
          <Button onClick={() => cancelAcc()} color="primary">
            Cancelar conta
          </Button>
        </DialogActions>
      </Dialog>

      <ColorButton variant="contained" style={{background: '#e63946', color: '#fff'}} className={classes.margin} onClick={handleClickOpen3}>
        Apagar Conta
      </ColorButton>
      <Dialog open={openDialog3} onClose={handleClose3} aria-labelledby="form-dialog-title">
        {loadingDelete && <SpinnerOverlay />}
        <DialogTitle id="form-dialog-title" style={{background: '#e63946', color: '#fff'}}>
          Apagar conta
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Typography variant="title" color="inherit" noWrap>
            Deseja realmente deletar essa conta? 
            
          </Typography>
          <Typography variant="subheading" noWrap style={{color: '#e63946'}}>
            Está operação não poderá ser desfeita!
          </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose3} color="primary">
            Voltar
          </Button>
          <Button onClick={() => deleteAcc()} color="primary">
            Apagar conta
          </Button>
        </DialogActions>
      </Dialog>

      <ColorButton variant="contained" style={{background: '#7c39e6', color: '#fff'}} className={classes.margin} onClick={handleClickOpen4}>
        Remover Status SPD
      </ColorButton>
      <Dialog open={openDialog4} onClose={handleClose4} aria-labelledby="form-dialog-title">
        {loadingUpdateUser && <SpinnerOverlay />}
        <DialogTitle id="form-dialog-title" style={{background: '#e63946', color: '#fff'}}>
        Remover Status SPD
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Typography variant="title" color="inherit" noWrap>
            Deseja realmente remover o status SPD desta conta? 
          </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose4} color="primary">
            Voltar
          </Button>
          <Button onClick={() => removeSpdStatus()} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

const ColorButton = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[200],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default FormDialog;
